import { useLazyQuery } from "@apollo/client";
import {
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { gql } from "apollo-boost";
import React, { useEffect, useState } from "react";
import { CustomCircularProgress, NoData } from "../../hoc/CustomComponents";
import useStyles from "./styles";
import moment from "moment";
import ViewServiceLogs from "./ViewServiceLogs";
import { debounce } from "throttle-debounce";
const logColumns = [
  {
    name: "Sr.No",
    minWidth: 100,
  },
  {
    name: "VIN",
    minWidth: 100,
  },
  {
    name: "Ticket ID",
    minWidth: 100,
  },
];

const columns = [
  {
    name: "Sr.No",
    minWidth: 100,
  },

  {
    name: "VIN",
    minWidth: 100,
  },
  {
    name: "Ticket ID",
    minWidth: 100,
  },
  {
    name: "Category",
    minWidth: 100,
  },
  {
    name: "Type",
    minWidth: 100,
  },
  {
    name: "Ticket Status",
    minWidth: 100,
  },
  {
    name: "Request Status",
    minWidth: 100,
  },
  {
    name: "Remarks",
    minWidth: 100,
  },
  {
    name: "Attachments",
    minWidth: 100,
  },
  {
    name: "CLA Issued On",
    minWidth: 100,
  },
  {
    name: "Vaahan Issued On",
    minWidth: 100,
  },
  {
    name: "Created On",
    minWidth: 100,
  },
];

const GET_TICKET = gql`
  query($serviceUserId: Int, $oemId: Int) {
    getVinTickets(serviceUserId: $serviceUserId, oemId: $oemId) {
      code
      message
      logs {
        loginId
        requestId
        vin
        otp
        request_reason
        request_status
        ticket_status
        otpSentTime
        createdOn
      }
    }
  }
`;

function ServiceUserLogs(props) {
  const classes = useStyles();
  const [allTicket, setAllTickets] = useState([]);
  const [requestId, setRequestId] = useState(null);
  const [openLogs, setOpenLogs] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [searchInput, setSearchInput] = useState();
  const [totalCount, setTotalCount] = useState(0);
  let [next, setNext] = useState(0);
  const [allData, setAlldata] = useState([]);
  const [getAllTickets, { loading }] = useLazyQuery(GET_TICKET, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      console.log(res, "res");
      if (res.getVinTickets.code == 200) {
        setSearchInput("");
        setRowsPerPage(rowsPerPage);
        setPage(0);
        setNext(0);
        setAllTickets(res.getVinTickets.logs);
        setAlldata(res.getVinTickets.logs);
        setTotalCount(res.getVinTickets.logs.length);
      } else {
        setAllTickets([]);
        setAlldata([]);
      }
    },
  });

  const viewLogs = (log) => {
    setRequestId(log.requestId);
    setOpenLogs(true);
  };

  const closeLogs = () => {
    setOpenLogs(false);
    setRequestId(null);
  };

  const handleChangePage = (event, newPage) => {
    // setSearchInput("");
    // setAllTickets(allData);
    setNext(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // setSearchInput("");
    // setAllTickets(allData);
    setRowsPerPage(+event.target.value);
    setPage(0);
    setNext(0);
  };
  const handleSearchChange = (event) => {
    // setFilterText(event.target.value);
    setSearchInput(event.target.value);
    setTimeout(debounceSearchRender(event.target.value), 2000);
  };

  const debounceSearchRender = debounce(1000, async (event) => {
    if (event) {
      const filteredRows = allData.filter((row) => {
        const searchText = event.toLowerCase().trim();

        return (
          (row.vin && row.vin.toLowerCase().includes(searchText)) ||
          (row.requestId && row.requestId.toLowerCase().includes(searchText))
        );
      });

      setAllTickets(filteredRows);
      setTotalCount(filteredRows.length);
      //to manage the pagination on search ,setting pagination to intial values
      // setRowsPerPage(20);
      setPage(0);
      setNext(0);
    } else {
      await getAllTickets({
        variables: {
          serviceUserId: props.token.id,
          oemId: props.oem,
        },
      });
    }
  });
  useEffect(() => {
    getAllTickets({
      variables: {
        serviceUserId: props.token.id,
        oemId: props.oem,
      },
    });
  }, [props.token.id, props.oem]);
  return (
    <Grid container>
      <ViewServiceLogs
        open={openLogs}
        columns={columns}
        requestId={requestId}
        closeLogs={closeLogs}
      />
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4">Service User Ticket Logs</Typography>
          </Grid>
          <Grid item xs={12} style={{paddingTop:'0px'}}>
            <Grid
              container
              style={{
                backgroundColor: "#FFFFFF",
                paddingTop: "0px",

                // marginLeft: "-10px !important",
              }}
            >
              <Grid
                item
                xs={6}
                style={{
                  paddingBottom: "8px",
                  paddingTop: "8px",
                  paddingLeft: "8px",
                }}
              >
                <TextField
                  placeholder="Search Number"
                  variant="outlined"
                  value={searchInput}
                  onChange={(e) => handleSearchChange(e)}
                  // onClick={handleSearch}
                  style={{
                    width: "80%",
                    backgroundColor: "#F3F3F3 ",
                  }}
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* <SearchIcon style={{ paddingLeft: "3px" }} /> */}
                      </InputAdornment>
                    ),
                  }}
                  className={classes.textfield}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={12} style={{maxHeight:'60vh'}}>
                {loading ? (
                  <CustomCircularProgress />
                ) : allTicket && allTicket.length > 0 ? (
                  <Paper>
                    <TableContainer
                      style={{maxHeight: "calc(100vh - 300px)" , overflow: "scroll" }}
                    >
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {logColumns.map((column) => (
                              <TableCell
                                align="center"
                                style={{
                                  minWidth: column.minWidth,
                                  font:
                                    "normal normal 600 15px/20px Nunito Sans",
                                  backgroundColor: "#faf8f7",
                                }}
                                className={classes.tableHead}
                              >
                                {column.name}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {allTicket
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                              let createdDate = moment
                                .unix(row.createdOn)
                                .format("DD-MM-YYYY HH:mm:ss");
                              return (
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {next + (index + 1)}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                    onClick={() => viewLogs(row)}
                                    style={{
                                      cursor: "pointer",
                                      color: "#5CB593",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    {row.vin}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.requestId}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[20, 50,100]}
                      component="div"
                      count={totalCount || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                ) : (
                  <NoData />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default ServiceUserLogs;
