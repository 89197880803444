import {
  Grid,
  InputAdornment,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import useStyles from "./styles";
import React, { useState, useEffect, useCallback } from "react";
import SearchIcon from "@mui/icons-material/Search";
import withSnackbar from "../../components/Snackbar/withSnackbar";
import axios from "axios";
import {
  gql,
  useLazyQuery,
  useApolloClient,
  useMutation,
  useQuery,
} from "@apollo/client";
import { ColorButton } from "../../hoc/PaletteComponent";
import { debounce } from "throttle-debounce";
import { NoData, CustomCircularProgress } from "../../hoc/CustomComponents";
import LogsByVin from "./LogsByVin";
import moment from "moment";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
const getFeatureAccess = gql`
  query ($featureId: Int!) {
    getFeatureAccess(featureId: $featureId) {
      id
      userId
      featureId
      createPermission
      readPermission
      updatePermission
      deletePermission
    }
  }
`;

const GET_ALL_VIN_IMEI_LOGS = gql`
  query ($oemId: Int!, $limit: Int!, $offset: Int!, $searchValue: String) {
    getAllVinImeiLogs(
      oemId: $oemId
      limit: $limit
      offset: $offset
      searchValue: $searchValue
    ) {
      code
      message
      mappedData {
        totalCount
        rows {
          id
          vin
          imei
          vehicleType
          vehicleModel
          fuelType
          createdOn
          status
          lat
          long
          pluscode
          deviceTS
          serverTS
          packetType
          eoltCompletedOn
          firmwareVersion
          vinImeiMappedOn
          engineNumber
        }
      }
    }
  }
`;

const columns = [
  {
    name: "Sr.No",
    minWidth: 100,
  },
  {
    name: "VIN",
    minWidth: 200,
  },
  {
    name: "IMEI",
    minWidth: 200,
  },
  {
    name: "Engine Number",
    minWidth: 200,
  },

  {
    name: "Status",
    minWidth: 200,
  },
  {
    name: "VIN IMEI Mapped On",
    minWidth: 200,
  },
  {
    name: "Created On",
    minWidth: 200,
  },
  {
    name: "Lattitude",
    minWidth: 200,
  },
  {
    name: "Longitude",
    minWidth: 200,
  },

  {
    name: "Pluscode",
    minWidth: 200,
  },

  {
    name: "Device TimeStamp",
    minWidth: 200,
  },
  {
    name: "Server TimeStamp",
    minWidth: 200,
  },
  {
    name: "PacketType",
    minWidth: 200,
  },
  {
    name: "EOLT Completed On",
    minWidth: 200,
  },
];

function VinImeiLogs(props) {
  const [readPermission, setReadPermission] = useState(false);
  const [createPermission, setCreatePermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);

  const [allLogs, setLogs] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [vinLogs, setVinLogs] = useState(false);
  const [selectedVin, setSelectedVin] = useState(null);
  let [next, setNext] = useState(0);
  const [searchVal, setSearchVal] = useState();
  let id;
  let history = useHistory();
  if (typeof props.featureId != "undefined") {
    localStorage.setItem("featureId", props.featureId);
    id = props.featureId;
  } else {
    id = parseInt(localStorage.getItem("featureId"));
  }

  /**
   * @summary This handles the page change in pagination
   */
  const handleChangePage = async (event, newPage) => {
    setNext(newPage * rowsPerPage);
    setPage(newPage);
    await getAllVinImeiLogs({
      variables: { oemId: props.oem, limit: rowsPerPage, offset: newPage, searchValue:  searchVal},
    });
  };

  /**
   * @summary This handles the row change in pagination
   */
  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setNext(0);
    await getAllVinImeiLogs({
      variables: { oemId: props.oem, limit: event.target.value, offset: 0, searchValue: searchVal },
    });
  };

  /**
   * @summary This fetches all the Vin Imei logs
   */
  const [getAllVinImeiLogs, { loading: logsLoading, data: campaign }] =
    useLazyQuery(GET_ALL_VIN_IMEI_LOGS, {
      fetchPolicy: "network-only",
      onError: ({ graphQLErrors, networkError }) => {
        if (networkError) {
          props.setMessage(`Message: ${networkError}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        }

        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            props.setMessage(`Message: ${message}`);
            props.setNetworkError(true);
            props.setSnackbar(true);
          });
        }
      },
      onCompleted: async (res) => {
        console.log(res, "res");
        if (res.getAllVinImeiLogs.code == 200) {
          setLogs(res.getAllVinImeiLogs.mappedData.rows);
          setTotalCount(res.getAllVinImeiLogs.mappedData.totalCount);
        }
      },
    });

  /**
   * @summary This fetches the access of the user
   */
  const [getAccess, { loading, error, data }] = useLazyQuery(getFeatureAccess, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      console.log("resss----", result);
      if (result && result.getFeatureAccess) {
        result.getFeatureAccess.forEach((element) => {
          if (element.readPermission === 1) {
            setReadPermission(true);
          } else {
            setReadPermission(false);
          }
          if (element.createPermission === 1) {
            setCreatePermission(true);
          } else {
            setCreatePermission(false);
          }
          if (element.updatePermission === 1) {
            setUpdatePermission(true);
          } else {
            setUpdatePermission(false);
          }
          if (element.deletePermission === 1) {
            setDeletePermission(true);
          } else {
            setDeletePermission(false);
          }
        });
      }
    },
  });

  /**
   * @summary handles Search value
   */
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  }
  
  /**
   * @summary returns the filtered array for search
   */
  const debounceSearchRender = useCallback(debounce(async (input) => {
    console.log(props)
    setPage(0);
    setNext(0);
    if (input) 
    {
      await getAllVinImeiLogs({
        variables: {
          oemId: props.oem,
          limit: rowsPerPage,
          offset: 0,
          // searchValue: input.toLowerCase(),
          searchValue: input,
        },
      });
    } else {
      console.log("else");
      await getAllVinImeiLogs({
        variables: {
          oemId: props.oem,
          limit: rowsPerPage,
          offset: page,
        },
      });
    }
  }, 1000), [props.oem,totalCount, rowsPerPage, page]);
 
  const handleSearchChange = (event) => {
    const input = event.target.value;
    setSearchVal(input);
    debounceSearchRender(input);
  };

  // const handleSearchChange = (event) => {
  //   console.log(event.target.value, "value");
  //   setTimeout(debounceSearchRender(event.target.value), 2000);
  // };

  // const debounceSearchRender = debounce(1000, async (event) => {
  //   if (event.toLowerCase().trim() != "") {
  //     await getAllVinImeiLogs({
  //       variables: {
  //         oemId: props.oem,
  //         limit: 20000,
  //         offset: 0,
  //         searchValue: event.toLowerCase(),
  //       },
  //     });
  //   } else {
  //     console.log("else");
  //     await getAllVinImeiLogs({
  //       variables: {
  //         oemId: props.oem,
  //         limit: rowsPerPage,
  //         offset: page,
  //       },
  //     });
  //   }
  // });

  const handleVinLogs = (vin) => {
    setSelectedVin(vin);
    vinLogs ? setVinLogs(false) : setVinLogs(true);
  };

  useEffect(() => {
    getAccess({
      variables: {
        featureId: id,
      },
    });
  }, []);

  useEffect(() => {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    var token = localStorage.getItem("id_token");
    var decoded = token && jwt_decode(token);
    if (!token || currentTimestamp > decoded.exp) {
      localStorage.clear();
      history.push({
        pathname: "/login",
      });
    } else {
      getAllVinImeiLogs({
        variables: {
          oemId: props.oem,
          limit: rowsPerPage,
          offset: page,
        },
      });
    }
    //removed previous snackbar state
    props.setSnackbar(false);
  }, [props.oem]);
  const classes = useStyles();
  return (
    <Grid container>
      {vinLogs && (
        <LogsByVin
          open={vinLogs}
          allLogs={allLogs}
          columns={columns}
          handleVinLogs={handleVinLogs}
          selectedVin={selectedVin}
          oem={props.oem}
        />
      )}
      <Grid item xs={12}>
        <Grid container>
          <Grid item></Grid>

          <Grid item xs={12} style={{ marginTop: "10px" }}>
            <Grid
              container
              style={{
                backgroundColor: "#FFFFFF",
                padding: "8px 0px 0px 16px",
              }}
            >
              <Grid item xs={6}>
                <TextField
                  id="search_imei"
                  placeholder="Search Number"
                  variant="outlined"
                  style={{ width: "80%", backgroundColor: "#F3F3F3 " }}
                  size="small"
                  onChange={(e) => handleSearchChange(e)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ paddingLeft: "3px" }} />
                      </InputAdornment>
                    ),
                  }}
                  className={classes.textfield}
                />
              </Grid>
              {/* <Grid item xs={6}></Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} style={{maxHeight:'60vh'}}>
            {logsLoading ? (
              <Grid item style={{ backgroundColor: "#FFFFFF" }}>
                <CustomCircularProgress />
              </Grid>
            ) : totalCount > 0 ? (
              <Grid
                item
                style={{ backgroundColor: "#FFFFFF", paddingTop: "8px" }}
              >
                <Paper>
                <TableContainer className={classes.container} style={{maxHeight: "calc(100vh - 300px)"}}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            align="center"
                            style={{
                              minWidth: column.minWidth,
                              font: "normal normal 600 15px/20px Nunito Sans",
                              backgroundColor: "#faf8f7",
                            }}
                            className={classes.tableHead}
                          >
                            {column.name}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allLogs.map((row, index) => {
                        let createdDate = moment
                          .unix(row.createdOn)
                          .format("DD-MM-YYYY HH:mm:ss");
                        let deviceTS = row.deviceTS
                          ? moment
                              .unix(row.deviceTS)
                              .format("DD-MM-YYYY HH:mm:ss")
                          : null;
                        let serverTS = row.serverTS
                          ? moment
                              .unix(row.serverTS)
                              .format("DD-MM-YYYY HH:mm:ss")
                          : null;
                        let eoltTS = row.eoltCompletedOn
                          ? moment
                              .unix(row.eoltCompletedOn)
                              .format("DD-MM-YYYY HH:mm:ss")
                          : null;

                        let vinImeiMappedOn = row.vinImeiMappedOn
                          ? moment
                              .unix(row.vinImeiMappedOn)
                              .format("DD-MM-YYYY HH:mm:ss")
                          : null;
                        return (
                          <TableRow>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {next + (index + 1)}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                              onClick={() => handleVinLogs(row.vin)}
                              style={{
                                cursor: "pointer",
                                color: "#5CB593",
                                textDecoration: "underline",
                              }}
                            >
                              {row.vin}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {row.imei ? row.imei : "NA"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {row.engineNumber
                                ? row.engineNumber
                                : "NA"}
                            </TableCell>

                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {row.status}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {vinImeiMappedOn
                                ? vinImeiMappedOn
                                : "NA"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {createdDate}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {row.lat ? row.lat : "NA"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {row.long ? row.long : "NA"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {row.pluscode ? row.pluscode : "NA"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {deviceTS ? deviceTS : "NA"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {serverTS ? serverTS : "NA"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {row.packetType ? row.packetType : "NA"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {eoltTS ? eoltTS : "NA"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[20,50, 100]}
                  component="div"
                  count={totalCount ? totalCount : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </Paper>
              </Grid>
            ) : (
              <Grid container style={{ backgroundColor: "#FFFFFF" }}>
                <NoData />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withSnackbar(VinImeiLogs);
