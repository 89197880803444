import React, { useState, useEffect } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import useStyles from "./styles";
import withSnackbar from "../../components/Snackbar/withSnackbar";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "throttle-debounce";
import { CustomCircularProgress, NoData } from "../../hoc/CustomComponents";
import { ColorButton, CustomButton } from "../../hoc/PaletteComponent";
import moment from "moment";
import CertificateUploadDialog from "./CertificateUpload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Grid,
  Typography,
  TextField,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  Button,
  InputAdornment,
  TablePagination,
  Paper,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { debounceSearchRender } from "mui-datatables";
import axios from "axios";
import UpdateTicket from "./UpdateTicket";
import AssignTicket from "./AssignTicket";
import Telematics from "./Telematics";
import ViewServiceLogs from "../ServiceUserLogs/ViewServiceLogs";
import FileSaver from "file-saver";
const getFeatureAccess = gql`
  query ($featureId: Int!) {
    getFeatureAccess(featureId: $featureId) {
      id
      userId
      featureId
      createPermission
      readPermission
      updatePermission
      deletePermission
    }
  }
`;

const UPLOAD_URL = gql`
mutation ($fileExtension: String!) {
  certificateFileUpload(fileExtension: $fileExtension) {
      code
      remarks
      message
      filename
      url
    }
  }
`;

const GET_UPLOAD_URL = gql`
  query ($fileExtension: String!) {
    getCertificateFileUploadUrl(fileExtension: $fileExtension) {
      code
      remarks
      message
      filename
      url
    }
  }
`;

const UPDATE_FILE_STATUS = gql`
  mutation ($id: Int!, $fileType: String!, $Certificate: String!) {
    updateCompletedStatus(
      id: $id
      fileType: $fileType
      Certificate: $Certificate
    ) {
      code
      remarks
      message
    }
  }
`;

const GET_VEHICLE_CERTIFICATE = gql`
  query ($oemId: Int!, $status: Int!, $loginId: Int!,$serviceStatus: String) {
    getOemVehicleData(oemId: $oemId, status: $status, loginId: $loginId,serviceStatus:$serviceStatus) {
      id
      ticket_number
      vin
      imei
      chassis_no
      vehicleModel
      status
      simPlanType
      inprogress_date
      device_model
      device_serialno
      engine_no
      customer_name
      customer_no
      state
      rto_name
      rto_no
      dealer_name
      alt_dealername
      alt_dealerno
      select_project
      customer_altno
      vehicle_oldnew
      vahaan_file
      common_file
      dealer_contact_name
      dealer_contact_number
      year_of_manufacture
      iccid
      payment_by
      otp
      otp_datetime
      otp_id
      expiry_on
      login_id
      msisdn1
      msisdn2
      ticketStatus
      customerOtp
      otpSendTime
      reason
      state_name
      customer_address
      vehicle_name
      payment_status
      requestStatus
      requestReason
      common_file_date
      vahaan_file_date
    }
  }
`;

const Update_Ticket = gql`
  mutation (
    $loginId: Int!
    $ticketId: String!
    $vin: String!
    $status: String!
    $reason: String
    $otp: Int
    $otpSentTime: String
  ) {
    updateTicketDetails(
      loginId: $loginId
      ticketId: $ticketId
      vin: $vin
      status: $status
      reason: $reason
      otp: $otp
      otpSentTime: $otpSentTime
    ) {
      code
      message
    }
  }
`;

const GET_ALL_CATEGORIES = gql`
  query ($oemId: Int) {
    getTicketCategory(oemId: $oemId) {
      code
      status
      message
      data {
        id
        oemId
        ticket_category_name
        status
      }
    }
  }
`;

const GET_SUB_CATEGORY = gql`
  query ($categoryId: Int!) {
    getSubCategoryByCategory(categoryId: $categoryId) {
      code
      status
      message
      data {
        id
        ticket_category_id
        sub_category_name
        status
      }
    }
  }
`;
const ASSIGN_TO_L2 = gql`
  mutation (
    $oemId: Int!
    $loginId: Int
    $categoryId: Int!
    $subCategoryId: Int!
    $vin: String!
    $requestId: String!
    $remarks: String
    $requestStatus: String!
    $uploadFileName: String
    $ownerId: Int
  ) {
    assignTicketsToL2(
      oemId: $oemId
      loginId: $loginId
      categoryId: $categoryId
      subCategoryId: $subCategoryId
      vin: $vin
      requestId: $requestId
      remarks: $remarks
      requestStatus: $requestStatus
      uploadFileName: $uploadFileName
      ownerId: $ownerId
    ) {
      code
      status
      message
    }
  }
`;

const UPLOAD_TICKET_FILES = gql`
  mutation ($fileExtension: String!) {
    ticketLogsFileUpload(fileExtension: $fileExtension) {
      code
      remarks
      message
      filename
      url
    }
  }
`;

const GET_ADDRESS = gql`
  query ($plusCode: String, $lat: String, $lng: String) {
    getAddressForPluscode(plusCode: $plusCode, lat: $lat, lng: $lng) {
      statusCode
      location
    }
  }
`;

const CREATE_LOGS = gql`
  mutation (
    $oemId: Int!
    $loginId: Int
    $otp: String
    $categoryId: Int
    $subCategoryId: Int
    $vin: String!
    $requestId: String!
    $ticketRemarks: String
    $ticketStatus: String
    $requestStatus: String
    $uploadFileName: String
    $ownerId: Int
  ) {
    createRequestLogs(
      oemId: $oemId
      loginId: $loginId
      otp: $otp
      categoryId: $categoryId
      subCategoryId: $subCategoryId
      vin: $vin
      requestId: $requestId
      ticketRemarks: $ticketRemarks
      ticketStatus: $ticketStatus
      requestStatus: $requestStatus
      uploadFileName: $uploadFileName
      ownerId: $ownerId
    ) {
      code
      status
      message
    }
  }
`;

const GET_LATEST_DETAILS = gql`
  query ($Vin: String!) {
    getDeviceDetailsByVin(Vin: $Vin) {
      code
      message
      data {
        vin
        imei
        latitude
        longitude
        serverTS
        packetType
        firmwareVersion
      }
    }
  }
`;

const GET_DOWNLOAD_URL = gql`
  query($filename: String!) {
    getDownloadURL(filename: $filename) {
      code
      remarks
      message
      url
    }
  }
`;

const EXCEL_DOWNLOAD = gql`
mutation ($oemId: Int!, $downloadFor: String!, $status:Int, $serviceStatus:String, $loginId:Int) {
  excelFileDownload(oemId: $oemId, downloadFor: $downloadFor, status : $status, serviceStatus: $serviceStatus, loginId: $loginId) {
    downloadUrl
  }
}
`;

const colums = [
  {
    label: "Sr.No",
    minWidth: 100,
    align: "center",
  },
  {
    label: "Request ID",
    minWidth: 200,
    align: "center",
  },
  {
    label: "VIN",
    minWidth: 100,
    align: "center",
  },
  {
    label: "Common Layer Upload",
    minWidth: 180,
    align: "center",
  },
  {
    label: "Fitment Upload",
    minWidth: 150,
    align: "center",
  },
  {
    label: "Common Layer Download",
    minWidth: 200,
    align: "center",
  },
  {
    label: "CLA Issued On",
    minWidth: 180,
    align: "center",
  },
  {
    label: "Fitment Issued On",
    minWidth: 180,
    align: "center",
  },
  {
    label: "Project Name",
    minWidth: 200,
    align: "center",
  },
  {
    label: "InProgress Date",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Device Model",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Device Serial Number",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Device IMEI Number",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Engine Number",
    minWidth: 200,
    align: "center",
  },
  {
    label: "ICCID Number",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Customer Name",
    minWidth: 350,
    align: "center",
  },
  {
    label: "Customer Number",
    minWidth: 150,
    align: "center",
  },
  {
    label: "Customer OTP number",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Customer Address",
    minWidth: 500,
    align: "center",
  },
  // {
  //   label: "Vehicle Name",
  //   minWidth: 150,
  //   align: "center",
  // },
  {
    label: "Vehicle Model",
    minWidth: 150,
    align: "center",
  },
  {
    label: "Registration State",
    minWidth: 200,
    align: "center",
  },
  {
    label: "RTO Number",
    minWidth: 150,
    align: "center",
  },
  {
    label: "Dealer Name",
    minWidth: 350,
    align: "center",
  },
  {
    label: "Dealer Contact Number",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Alternate Dealer Name",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Alternate Dealer Number",
    minWidth: 200,
    align: "center",
  },

  {
    label: "Payment Status",
    minWidth: 150,
    align: "center",
  },
  {
    label: "SIM Activation status ",
    minWidth: 200,
    align: "center",
  },
  {
    label: "MSISDN1",
    minWidth: 150,
    align: "center",
  },
  {
    label: "MSISDN2",
    minWidth: 150,
    align: "center",
  },
  {
    label: "Vehicle Type",
    minWidth: 150,
    align: "center",
  },
  {
    label: "Year Of Manufacture",
    minWidth: 180,
    align: "center",
  },
  {
    label: "Request Status",
    minWidth: 150,
    align: "center",
  },
  {
    label: "OTP",
    minWidth: 100,
    align: "center",
  },
  {
    label: "OTP Sent On",
    minWidth: 150,
    align: "center",
  },
  {
    label: "Reason",
    minWidth: 800,
    align: "center",
  },
  {
    label: "Telematics Check",
    minWidth: 150,
    align: "center",
  },
  {
    label: "Assign To L2",
    minWidth: 150,
    align: "center",
  },
];
const columnsForLogs = [
  {
    name: "Sr.No",
    minWidth: 100,
  },

  {
    name: "VIN",
    minWidth: 100,
  },
  {
    name: "Ticket ID",
    minWidth: 100,
  },
  {
    name: "Category",
    minWidth: 100,
  },
  {
    name: "Type",
    minWidth: 100,
  },
  {
    name: "Ticket Status",
    minWidth: 100,
  },
  {
    name: "Request Status",
    minWidth: 100,
  },
  {
    name: "Remarks",
    minWidth: 100,
  },
  {
    name: "Attachments",
    minWidth: 100,
  },
  {
    name: "CLA Issued On",
    minWidth: 100,
  },
  {
    name: "Vaahan Issued On",
    minWidth: 100,
  },
  {
    name: "Created On",
    minWidth: 100,
  },
];
function CertificationManager(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [readPermission, setReadPermission] = useState(false);
  const [createPermission, setCreatePermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [allData, setAlldata] = useState([]);
  const [certificateData, setCertificateData] = useState([]);
  const [vahanFile, setVahanFile] = useState(null);
  const [commonFile, setCommonFile] = useState(null);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [certificateId, setCertificateId] = useState(null);
  const [uploadFile, setuploadFile] = useState(null);
  const [Vahaan, setVahaan] = useState(true);
  const [Common, setCommon] = useState(true);
  const [openTicket, setOpenTicket] = useState(false);
  const [ticketId, setTicketId] = useState(null);
  const [vin, setVin] = useState(null);
  const [otp, setOtp] = useState(null);
  const [reason, setReason] = useState(null);
  const [status, setStatus] = useState(null);
  const [login_id, setLoginId] = useState(null);
  const [openAssign, setAssign] = useState(false);
  const [assignReason, setAssignReason] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const [allCategories, setAllCategories] = useState([]);
  const [category, setCategory] = useState(null);
  const [allSubCat, setAllSubCat] = useState([]);
  const [subCategory, setSubCategory] = useState(null);
  const [remark, setRemarks] = useState(null);
  const [filename, setFileName] = useState(null);
  const [openTelematics, setOpenTelematics] = useState(false);
  const [deviceTS, setDeviceTS] = useState(null);
  const [serverTS, setServerTS] = useState(null);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [pluscode, setPlucCode] = useState(null);
  const [packetType, setPacketType] = useState(null);
  const [requestStatus, setRequestStatus] = useState(null);
  const [eoltVin, setEoltVin] = useState(null);
  const [eoltImei, setEoltImei] = useState(null);
  const [ticketStatus, setTicketStatus] = useState(null);
  const [validFile, setValidFile] = useState(true);
  const [searchInput, setSearchInput] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [openLogs, setOpenLogs] = useState(false);
  const [fileType, setFileType] = useState(null);
  const [fileUpload, setFileUpload] = useState(null);
  let [next, setNext] = useState(0);
  let id;
  console.log(props, "---props");
  if (typeof props.featureId != "undefined") {
    localStorage.setItem("featureId", props.featureId);
    id = props.featureId;
  } else {
    id = parseInt(localStorage.getItem("featureId"));
  }
  console.log(id, "idid");

  useEffect(() => {
    console.log(props, "props");
    getAccess({
      variables: {
        featureId: id,
      },
    });
    getVehicleCertificate({
      variables: {
        status: 2,
        oemId: props.oem,
        loginId: -1,
        serviceStatus: "ASSIGNED_TO_L1"
      },
    });

    // setAllCategories([
    //   {
    //     id: 1,
    //     oemId: 354,
    //     ticket_category_name: "KYC Issues",
    //     status: "ACTIVE",
    //   },
    //   {
    //     id: 2,
    //     oemId: 354,
    //     ticket_category_name: "Device Issues",
    //     status: "ACTIVE",
    //   },
    //   {
    //     id: 3,
    //     oemId: 354,
    //     ticket_category_name: "SIM Issues",
    //     status: "ACTIVE",
    //   },
    //   {
    //     id: 4,
    //     oemId: 354,
    //     ticket_category_name: "Portal Issues",
    //     status: "ACTIVE",
    //   },
    //   {
    //     id: 5,
    //     oemId: 354,
    //     ticket_category_name: "Sate Wise Issues",
    //     status: "ACTIVE",
    //   },
    // ]);
    //removed previous snackbar state
    props.setSnackbar(false);
  }, [props.oem]);
  /**
   * @summary handles Search value
   */
  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
    setTimeout(debounceSearchRender(event.target.value), 2000);
  };

  /**
   * @summary returns the filtered array for search
   */
  const debounceSearchRender = debounce(1000, async (event) => {
    if (event) {
      console.log(allData, "allData");
      const filteredRows = allData.filter((row) => {
        const searchText = event.toLowerCase().trim();

        return (
          (row.vin && row.vin.toLowerCase().includes(searchText)) ||
          (row.imei && row.imei.toLowerCase().includes(searchText)) ||
          (row.dealer_name &&
            row.dealer_name.toLowerCase().includes(searchText)) ||
          (row.device_serialno &&
            row.device_serialno.toLowerCase().includes(searchText)) ||
          (row.device_model &&
            row.device_model.toLowerCase().includes(searchText)) ||
          (row.select_project &&
            row.select_project.toLowerCase().includes(searchText)) ||
          (row.ticket_number &&
            row.ticket_number.toLowerCase().includes(searchText)) ||
          (row.simPlanType &&
            row.simPlanType.toLowerCase().includes(searchText)) ||
          (row.customer_name &&
            row.customer_name.toLowerCase().includes(searchText)) ||
          (row.vehicleModel &&
            row.vehicleModel.toLowerCase().includes(searchText)) ||
          (row.vehicle_name &&
            row.vehicle_name.toString().includes(searchText)) ||
          (row.iccid && row.iccid.toLowerCase().includes(searchText))
        );
      });
      setTotalCount(filteredRows.length);
      setCertificateData(filteredRows);
      // if (certificateData.length == 0) {
      //   await getVehicleCertificate({
      //     variables: {
      //       status: 3,
      //     },
      //   });
      // } else {
      //   setCertificateData(filteredRows);
      // }
      //to manage the pagination on search ,setting pagination to intial values
      // setRowsPerPage(20);
      setPage(0);
      setNext(0);
    } else {
      await getVehicleCertificate({
        variables: {
          status: 2,
          oemId: props.oem,
          loginId: -1,
          serviceStatus: "ASSIGNED_TO_L1"
        },
      });
    }
  });

  const handleChangePage = (event, newPage) => {
    // setSearchInput("");
    // setCertificateData(allData);
    setNext(newPage * rowsPerPage);
    setPage(newPage);
  };

  const viewLogs = (log) => {
    setLoginId(log.login_id);
    setRequestId(log.ticket_number);
    setOpenLogs(true);
  };

  const closeLogs = () => {
    setOpenLogs(false);
    setRequestId(null);
  };

  const handleChangeRowsPerPage = (event) => {
    // setSearchInput("");
    // setCertificateData(allData);
    setRowsPerPage(+event.target.value);
    setPage(0);
    setNext(0);
  };

  const handleOpen = (id, scope) => {
    setCertificateId(id);
    setFileType(scope);
    setUploadOpen(true);
  };

  const handleModelClose = () => {
    setUploadOpen(false);
    setCertificateId(null);
    setFileType(null);
    setFileUpload(null);
  };

  const [getAccess, { loading, error, data }] = useLazyQuery(getFeatureAccess, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      console.log("resss----", result);
      if (result && result.getFeatureAccess) {
        result.getFeatureAccess.forEach((element) => {
          console.log(result, "result");
          if (element.readPermission === 1) {
            setReadPermission(true);
          } else {
            setReadPermission(false);
          }
          if (element.createPermission === 1) {
            setCreatePermission(true);
          } else {
            setCreatePermission(false);
          }
          if (element.updatePermission === 1) {
            setUpdatePermission(true);
          } else {
            setUpdatePermission(false);
          }
          if (element.deletePermission === 1) {
            setDeletePermission(true);
          } else {
            setDeletePermission(false);
          }
        });
      }
    },
  });

  console.log(page, rowsPerPage, "page rowperpage");
  const [
    getVehicleCertificate,
    {
      loading: certficateLoading,
      error: certficateError,
      data: vehicleCertificateData,
    },
  ] = useLazyQuery(GET_VEHICLE_CERTIFICATE, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      setSearchInput("");
      setRowsPerPage(rowsPerPage);
      setPage(0);
      setNext(0);
      // console.log(result.getOemVehicleData, "datadat");
      setAlldata(result.getOemVehicleData);
      setTotalCount(result.getOemVehicleData.length);
      setCertificateData(result.getOemVehicleData);
    },
  });

  /**
   * @summary This closes the snackbar
   */
  const handleSnackbarClose = () => {
    setTimeout(() => {
      props.setSnackbar(false);
    }, 7000);
  };

  /**
   * @summary api  call to upload vahaan file
   */
  const [uploadURL] = useLazyQuery(GET_UPLOAD_URL, {
    fetchPolicy: "no-cache",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleSnackbarClose();
    },
    onCompleted: async (response) => {
      console.log(response, "responseVahaan");

      if (response && response.getCertificateFileUploadUrl.url) {
        await axios.put(response.getCertificateFileUploadUrl.url, uploadFile, {
          headers: {
              'x-ms-blob-type': 'BlockBlob',
              // 'Content-Type': 'application/octet-stream', // Adjust this based on your file type
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            let percentage = Math.round((100 * data.loaded) / data.total);

            props.setSnackbar(false);
          },
        });

        setFileUpload(response.getCertificateFileUploadUrl.filename);
        // setVahaan(false);
      } else if (response && response.getCertificateFileUploadUrl.code == 404) {
        setFileUpload(null);
        props.setMessage(response.getCertificateFileUploadUrl.message);
        props.setSnackbar(true);
        handleSnackbarClose();
      } else {
        setFileUpload(null);
        props.setMessage("Something went wrong,Please Try again!");
        props.setSnackbar(true);
        handleSnackbarClose();
      }
    },
  });

  /**
   * @summary Fetches a url for uploading a file and uploads the file
   */
  const onUpload = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    // setIsBulkUploadOpen(false);
    console.log("file----", file);
    console.log("validdiiitt---", validity);
    if (file) {
      //setUpload(true);
      //setProgressDialog(true);
      setuploadFile(file);
      props.setMessage("");

      props.setExcelError("");
      props.closeDialog(false);
      props.setExcelData(null);
      props.setUpload(false);
      if (validity.valid) {
        const fileExtension = file.name.substring(
          file.name.lastIndexOf(".") + 1
        );
        props.setMessage(`Uploading....`);
        props.setSnackbar(true);
        await uploadURL({
          variables: {
            fileExtension,
          },
        });
      }
    } else {
      console.log("file----", file);
      alert("No file selected");
    }
  };

  /**
   * @summary api  call to upload vahaan file
   */
  const [uploadURLForCommon] = useMutation(UPLOAD_URL, {
    fetchPolicy: "no-cache",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      //handleClose();
      handleSnackbarClose();
    },
    onCompleted: async (response) => {
      console.log(response, "responseCommon");

      if (response && response.certificateFileUpload.url) {
        await axios.put(response.certificateFileUpload.url, uploadFile, {
          headers: {
            'x-ms-blob-type': 'BlockBlob',
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            // let percentage = Math.round((100 * data.loaded) / data.total);
            props.setSnackbar(false);
          },
        });
        setCommonFile(response.certificateFileUpload.filename);
        setCommon(false);
        props.setSnackbar(false);
      } else if (response && response.certificateFileUpload.code == 404) {
        props.setMessage(response.certificateFileUpload.message);
        props.setSnackbar(true);
        handleSnackbarClose();
      } else {
        props.setMessage("Something went wrong,Please Try again!");
        props.setSnackbar(true);
        handleSnackbarClose();
      }
    },
  });

  /**
   * @summary Fetches a url for uploading a file and uploads the file
   */
  const onUploadCommon = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    // setIsBulkUploadOpen(false);
    console.log("file----", file);
    console.log("validdiiitt---", validity);
    if (file) {
      //setUpload(true);
      //setProgressDialog(true);
      setuploadFile(file);
      props.setMessage("");

      props.setExcelError("");
      props.closeDialog(false);
      props.setExcelData(null);
      props.setUpload(false);
      if (validity.valid) {
        const fileExtension = file.name.substring(
          file.name.lastIndexOf(".") + 1
        );
        props.setMessage(`Uploading....`);
        props.setSnackbar(true);
        await uploadURLForCommon({
          variables: {
            fileExtension,
          },
        });
      }
    } else {
      console.log("file----", file);
      alert("No file selected");
    }
  };

  /**
   * @summary api call to set kyc Details
   */
  const [
    updateStatus,
    { error: createError, loading: createloading, data: createData },
  ] = useMutation(UPDATE_FILE_STATUS, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleModelClose();
      handleSnackbarClose();
    },
    onCompleted: async (result) => {
      console.log(result, "result");
      if (result.updateCompletedStatus.code == 200) {
        props.setMessage(result.updateCompletedStatus.message);
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleModelClose();
      } else {
        if (result.updateCompletedStatus.code != 200) {
          props.setMessage(result.updateCompletedStatus.message);
          props.setNetworkError(true);
          props.setSnackbar(true);
          handleModelClose();
        }
      }
      handleSnackbarClose();
      await getVehicleCertificate({
        variables: { status: 2, oemId: props.oem, loginId: -1, serviceStatus: "ASSIGNED_TO_L1" },
      });
    },
  });

  const uploadCertificate = () => {
    console.log(certificateId, fileType, fileUpload, "variables");
    updateStatus({
      variables: {
        id: certificateId,
        fileType: fileType,
        Certificate: fileUpload,
      },
    });
  };

  /**
   * @summary to open modal of AS140request
   */
  const handleTicketModel = (row) => {
    setTicketId(row.ticket_number);
    setVin(row.vin);
    setOtp(row.customerOtp);
    setReason(row.reason);
    setStatus(row.requestStatus);
    setTicketStatus(row.ticketStatus);
    setLoginId(row.login_id);
    setOpenTicket(true);
  };

  const handleOtp = (e) => {
    setOtp(e.target.value);
  };
  const handleReason = (e) => {
    setReason(e.target.value);
  };

  const handleStatus = (e, select) => {
    if (select) {
      setStatus(select);
    } else {
      setStatus(null);
    }
  };

  const handleClose = () => {
    setOpenTicket(false);
  };

  /**
   * @summary submit the data of request Model
   */
  const handleSubmit = () => {
    console.log(ticketId, vin, otp, reason, status, "ticket details");
    let otpNo = otp && otp.toString();
    let otpReg = /^[0-9\b]+$/;
    // console.log("otp test;;;;;",otpReg.test(otpNo));

    if (otpNo && (otpNo.length < 4 || otpNo.length > 6 || (!otpReg.test(otpNo)))) {
      props.setMessage("OTP length should be 4-6 digits");
      props.setNetworkError(true);
      props.setSnackbar(true);
      handleSnackbarClose();
    } else {
      updateTicke({
        variables: {
          oemId: props.oem,
          loginId: login_id,
          requestId: ticketId,
          vin: vin,
          requestStatus: status,
          ticketStatus: ticketStatus,
          ticketRemarks: reason,
          otp: otp && otp.toString(),
          ownerId: props.token.id,
        },
      });
    }
  };

  /**
   * @summary opens the Assign modal
   */
  const handleAssign = (row) => {
    getAllCategories({
      // variables: {
      //   oemId: props.oem,
      // },
    });
    setVin(row.vin);
    setRequestId(row.ticket_number);
    setLoginId(row.login_id);

    setRequestStatus(row.requestStatus);

    setAssign(true);
    setFileName(null);
  };

  const handleCloseAssign = () => {
    setAssign(false);
    setRequestId(null);
    setLoginId(null);
    setCategory(null);
    // setAllCategories([]);
    setSubCategory(null);
    setRemarks(null);
    setAllSubCat([]);
    setValidFile(true);
    setFileName(null);
  };

  const handleAssignL2Submit = async () => {
    //submit assign api
    let variables = {
      oemId: props.oem,
      loginId: login_id,
      categoryId: category.id,
      subCategoryId: subCategory.id,
      vin: vin,
      requestId: requestId,
      remarks: remark,
      uploadFileName: filename,
      ownerId: props.token.id,
    };
    console.log(variables, "variables");
    await assignToL2({
      variables: {
        oemId: props.oem,
        loginId: login_id,
        categoryId: category.id,
        subCategoryId: subCategory.id,
        vin: vin,
        requestId: requestId,
        remarks: remark,
        requestStatus: "INPROGRESS",
        uploadFileName: filename,
        ownerId: props.token.id,
      },
    });
  };

  const [updateTicke] = useMutation(CREATE_LOGS, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleSnackbarClose();
      handleClose();
    },
    onCompleted: async (res) => {
      console.log(res);
      if (res.createRequestLogs.code == 200) {
        props.setMessage("Successfully updated the ticket");
      } else {
        props.setMessage("Failed to Update the Ticket");
      }
      props.setNetworkError(true);
      props.setSnackbar(true);
      handleSnackbarClose();
      handleClose();
      await getVehicleCertificate({
        variables: {
          status: 2,
          oemId: props.oem,
          loginId: -1,
          serviceStatus: "ASSIGNED_TO_L1"
        },
      });
    },
  });

  const handleCategory = (e, sel) => {
    console.log(sel, "Category");
    setSubCategory(null);
    setAllSubCat([]);
    if (sel) {
      getCategoryBy({
        variables: {
          categoryId: sel.id,
        },
      });
      setCategory(sel);
    } else {
      setCategory(null);
      setSubCategory(null);
    }
  };

  const handleSubCategory = (e, sel) => {
    if (sel) {
      setSubCategory(sel);
    } else {
      setSubCategory(null);
    }
  };

  const handleRemarks = (e) => {
    setRemarks(e);
  };

  /**
   * @summary This uploads the file to bucket
   */
  const upload = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (file) {
      const fileExtension = file.name.substring(file.name.lastIndexOf(".") + 1);
      props.setMessage("Uploading...");
      props.setNetworkError(false);
      props.setSnackbar(true);
      let fileSize = file.size / (1024 * 1024);
      if (fileSize >= 3) {
        props.setMessage("File Size more than 3mb is not allowed");
        setValidFile(false);
        props.setNetworkError(true);
        handleSnackbarClose();
      } else {
        await uploadTicketFiles({
          variables: {
            fileExtension: fileExtension,
          },
          onCompleted: async (res) => {
            console.log(res, "res in upload");
            if (res.ticketLogsFileUpload.code == 200) {
              await axios.put(res.ticketLogsFileUpload?.url, file, {
                headers: {
                  'x-ms-blob-type': 'BlockBlob',
                },
                onUploadProgress: (data) => {
                  props.setSnackbar(false);
                  setValidFile(true);
                  props.setNetworkError(true);
                  setFileName(res.ticketLogsFileUpload.filename);
                },
              });
            } else {
              props.setMessage(
                res.ticketLogsFileUpload.message || "Failed to Upload"
              );
              setValidFile(false);
              props.setNetworkError(true);
              handleSnackbarClose();
            }
          },
        });
      }
    } else {
      alert("No file selected");
      setFileName(null);
    }
  };

  const [getAllCategories] = useLazyQuery(GET_ALL_CATEGORIES, {
    fetchPolicy: "network-only",
    onCompleted: async (res) => {
      if (res?.getTicketCategory.code == 200) {
        let allcat = res.getTicketCategory.data.map((cat) => ({
          ticket_category_name: cat.ticket_category_name,
          id: cat.id,
        }));
        console.log(allcat);
        setAllCategories(allcat);
      }
    },
  });

  const [getCategoryBy, { loading: subCatLoading }] = useLazyQuery(
    GET_SUB_CATEGORY,
    {
      fetchPolicy: "network-only",
      onCompleted: (res) => {
        if (res.getSubCategoryByCategory.code == 200) {
          console.log(res, "res in cat");
          let allSubCat = res.getSubCategoryByCategory.data.map((sub) => ({
            sub_category_name: sub.sub_category_name,
            id: sub.id,
          }));
          setAllSubCat(allSubCat);
        }
      },
    }
  );

  const [assignToL2, { loading: assignLoading }] = useMutation(ASSIGN_TO_L2, {
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleSnackbarClose();
    },
    onCompleted: (res) => {
      console.log(res, "res in assign");
      if (res.assignTicketsToL2.code == 200) {
        props.setMessage(res.assignTicketsToL2.message);
        props.setNetworkError(true);
        props.setSnackbar(true);
        getVehicleCertificate({
          variables: {
            status: 2,
            oemId: props.oem,
            loginId: -1,
            serviceStatus: "ASSIGNED_TO_L1"
          },
        });
        handleCloseAssign();
      } else {
        props.setMessage(
          res.assignTicketsToL2.message
            ? res.assignTicketsToL2.message
            : "Failed To Assign"
        );
        props.setNetworkError(true);
        props.setSnackbar(true);
      }
      handleSnackbarClose();
      //call get vehicle api
    },
  });
  const [getLatestpacket] = useLazyQuery(GET_LATEST_DETAILS, {
    fetchPolicy: "network-only",
  });

  const handleTelematics = async (row) => {
    setEoltVin(row.vin);
    setEoltImei(row.imei);
    await getLatestpacket({
      variables: {
        Vin: row.vin,
      },
      onCompleted: (res) => {
        if (
          res.getDeviceDetailsByVin.code == 200 &&
          res.getDeviceDetailsByVin.data
        ) {
          let detail = res.getDeviceDetailsByVin.data;

          const deviceTS =
            detail.deviceTS &&
            moment.unix(detail.deviceTS).format("DD-MM-YYYY HH:mm:ss");
          const serverTS = moment
            .unix(detail.serverTS)
            .format("DD-MM-YYYY HH:mm:ss");
          setServerTS(serverTS);
          setDeviceTS(serverTS);
          setLat(detail.latitude);
          setLong(detail.longitude);
          setPacketType(detail.packetType);
          if (detail.latitude && detail.longitude) {
            getAddress({
              variables: {
                lat: detail.latitude,
                lng: detail.longitude,
              },
              onCompleted: (res) => {
                setPlucCode(res.getAddressForPluscode.location);
              },
            });
          }
        } else {
          setServerTS(null);
          setDeviceTS(null);
          setLat(null);
          setLong(null);
          setPlucCode(null);
          setPacketType(null);
        }
      },
    });
    setOpenTelematics(true);
  };

  const [getAddress, { error: addressError }] = useLazyQuery(GET_ADDRESS);

  /**
   * @summary api call to get latest call
   */
  const getTelematics = async (imei) => {
    let token = localStorage.getItem("id_token");
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_OEM_EOLT_URL,
      headers: {
        Authorization: `Bearer ${token}`,

        "Content-Type": "application/json",
      },
      data: {
        imeiList: [`t_${imei}`],
      },
    };

    let res = axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        return response.data;
      })
      .catch((error) => {
        // console.log(error);
        return error;
      });
    return res;
  };
  const closeTelematics = () => {
    setOpenTelematics(false);
  };
  const [uploadTicketFiles] = useMutation(UPLOAD_TICKET_FILES, {
    errorPolicy: "all",
  });


  const [
    onFileDownload,
    { loading: downloadLoading, error: downloadError, data: downloadData },
  ] = useLazyQuery(GET_DOWNLOAD_URL, {
    fetchPolicy: "network-only",

  });
  const onDownload = (filename) => {
    onFileDownload({
      variables: {
        filename: filename,
      },
      onCompleted: async (result) => {
        if (result.getDownloadURL && result.getDownloadURL.url) {
          const url = result.getDownloadURL.url;

          const res = await axios({
            url: url,
            method: "GET",
            headers: { Accept: "application/vnd.ms-excel" },
            responseType: "blob",
          });

          FileSaver.saveAs(new Blob([res.data]), filename);
        }
      },
    });
  };

  const [getDownloadUrl, { data: url, loading: downloading }] = useMutation(
    EXCEL_DOWNLOAD,
    {
      fetchPolicy: "network-only",
      onError: ({ graphQLErrors, networkError }) => {
        if (networkError) {
          props.setMessage(`Message: ${networkError}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        }
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            props.setMessage(`Message: ${message}`);
            props.setNetworkError(true);
            props.setSnackbar(true);
          });
        }
      },
      onCompleted: async (result) => {
        if (result.excelFileDownload.downloadUrl) {
          const res = await axios({
            url: result.excelFileDownload.downloadUrl,
            method: "GET",
            headers: { Accept: "application/vnd.ms-excel" },
            responseType: "blob", // important
          });

          FileSaver.saveAs(new Blob([res.data]), "Certification_Manager.xlsx");
        } else {
          props.setMessage("Something went wrong,Please Try again!");
          props.setSnackbar(true);
          handleCloseSnackbar();
        }
      },
    }
  );

  const DownloadExcel = () => {
    getDownloadUrl({
      variables: {
        oemId: props.oem,
        downloadFor: "CERTIFICATE_MANAGER",
        status: 2,
        loginId: -1,
        serviceStatus:"ASSIGNED_TO_L1",
      },
    });
  };

  /**
   * @summary closes the snackbar
   */
  const handleCloseSnackbar = () => {
    setTimeout(() => {
      props.setSnackbar(false);
    }, 7000);
  };

  return (
    <>
      <ViewServiceLogs
        open={openLogs}
        columns={columnsForLogs}
        requestId={requestId}
        closeLogs={closeLogs}
        loginId={login_id}
      />
      <CertificateUploadDialog
        isModelOpen={uploadOpen}
        handleModelClose={handleModelClose}
        certificateId={certificateId}
        onUpload={onUpload}
        // onUploadCommon={onUploadCommon}
        onSubmit={uploadCertificate}
        snackbarOpen={props.open}
        fileType={fileType}
        fileUpload={fileUpload}
      />
      <UpdateTicket
        open={openTicket}
        ticketId={ticketId}
        vin={vin}
        otp={otp}
        reason={reason}
        status={status}
        handleOtp={handleOtp}
        handleReason={handleReason}
        handleStatus={handleStatus}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
      <AssignTicket
        open={openAssign}
        vin={vin}
        handleCloseAssign={handleCloseAssign}
        handleAssignL2Submit={handleAssignL2Submit}
        requestId={requestId}
        allCategories={allCategories}
        category={category}
        subCategory={subCategory}
        allSubCat={allSubCat}
        handleSubCategory={handleSubCategory}
        handleCategory={handleCategory}
        handleRemarks={handleRemarks}
        remark={remark}
        upload={upload}
        filename={filename}
        snackbarOpen={props.open}
        assignLoading={assignLoading}
        subCatLoading={subCatLoading}
        validFile={validFile}
      />
      <Telematics
        open={openTelematics}
        vin={eoltVin}
        imei={eoltImei}
        deviceTS={deviceTS}
        serverTS={serverTS}
        lat={lat}
        long={long}
        location={pluscode}
        packetType={packetType}
        closeTelematics={closeTelematics}
      />
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item>
              <Typography variant="h4">Certification Manager</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ paddingTop: '8px' }}
            >
              <Grid
                container
                style={{
                  backgroundColor: "#FFFFFF",
                  paddingTop: "0px",
                  // marginLeft: "-10px !important",
                }}
              >
                <Grid
                  item
                  xs={6}
                  style={{
                    paddingBottom: "8px",
                    paddingTop: "8px",
                    paddingLeft: "8px",
                  }}
                >
                  <TextField
                    placeholder="Search Number"
                    variant="outlined"
                    style={{ width: "80%", backgroundColor: "#F3F3F3 " }}
                    size="small"
                    value={searchInput}
                    onChange={(e) => handleSearchChange(e)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ paddingLeft: "3px" }} />
                        </InputAdornment>
                      ),
                    }}
                    className={classes.textfield}
                  />
                  {downloading ? (
                    <CircularProgress style={{ color: "#57B793" }} />
                  ) : (
                    <IconButton
                      title="Download Excel"
                      aria-label="Download Excel"
                      //
                      onClick={DownloadExcel}
                      disabled={totalCount == 0}
                    >
                      <CloudDownloadIcon
                        style={{ fill: "#57B793", fontSize: 30 }}
                      />
                    </IconButton>
                  )}
                </Grid>

                <Grid item xs={12} style={{ maxHeight: '60vh' }}>
                  {certficateLoading ? (
                    <CustomCircularProgress />
                  ) : certificateData && certificateData.length > 0 ? (
                    <Paper>
                      <TableContainer className={classes.container} style={{ maxHeight: "calc(100vh - 300px)" }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {colums.map((column) => (
                                <>
                                  <TableCell
                                    align={column.align}
                                    style={{
                                      minWidth: column.minWidth,
                                      font: "normal normal 600 14px/18px Nunito Sans",
                                      letterSpacing: "0px",
                                      color: "#212121",
                                      borderRightWidth: 1,
                                      borderColor: "#E8E8E8",
                                      borderTop: "1px solid #E8E8E8",
                                      padding: '8px'
                                    }}
                                  >
                                    {column.label}
                                  </TableCell>
                                </>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {certificateData ? (
                              certificateData
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                  let inprogressDate = row.inprogress_date
                                    ? moment
                                      .unix(row.inprogress_date)
                                      .format("DD-MM-YYYY HH:mm:ss")
                                    : null;

                                  let otpDateTime = row.otp_datetime
                                    ? moment
                                      .unix(row.otp_datetime)
                                      .format("DD-MM-YYYY HH:mm:ss")
                                    : null;
                                  let otpSentOn = row.otpSendTime
                                    ? moment
                                      .unix(row.otpSendTime)
                                      .format("DD-MM-YYYY HH:mm:ss")
                                    : null;
                                  let claIssuedOn = row.common_file_date
                                    ? moment
                                      .unix(row.common_file_date)
                                      .format("DD-MM-YYYY HH:mm:ss")
                                    : null;
                                  let vahIssuedOn = row.vahaan_file_date
                                    ? moment
                                      .unix(row.vahaan_file_date)
                                      .format("DD-MM-YYYY HH:mm:ss")
                                    : null;
                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                    >
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {next + (index + 1)}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                        onClick={() => handleTicketModel(row)}
                                        style={{
                                          // pointerEvents:
                                          //   row.simPlanType ==
                                          //   "DUAL_PROFILE_ACTIVATION_INPROGRESS"
                                          //     ? "none"
                                          //     : false,
                                          cursor: "pointer",
                                          color: "#5CB593",
                                          textDecoration: "underline",
                                        }}
                                      >
                                        {row.ticket_number}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                        onClick={() => viewLogs(row)}
                                        sx={{
                                          cursor: "pointer",
                                          color: "#5CB593",
                                          textDecoration: "underline",
                                        }}
                                      >
                                        {row.vin}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        <CustomButton
                                          onClick={() =>
                                            handleOpen(row.id, "common")
                                          }
                                          disabled={
                                            row.simPlanType !=
                                            "COMMERCIALLY_ACTIVATED" ||
                                            row.common_file !== null ||
                                            !row.customerOtp
                                          }
                                        >
                                          <CloudUploadIcon />
                                        </CustomButton>
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        <CustomButton
                                          variant="contained"
                                          onClick={() =>
                                            handleOpen(row.id, "vahaan")
                                          }
                                          disabled={
                                            row.simPlanType !=
                                            "COMMERCIALLY_ACTIVATED" ||
                                            row.vahaan_file !== null ||
                                            !row.customerOtp
                                          }
                                        >
                                          <CloudUploadIcon />
                                        </CustomButton>
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.common_file ? (
                                          <CustomButton
                                            style={{ fontSize: "8px" }}
                                            onClick={() =>
                                              onDownload(row.common_file)
                                            }
                                            variant="contained"
                                          >
                                            Download
                                          </CustomButton>
                                        ) : (
                                          <CustomButton
                                            style={{ fontSize: "8px" }}
                                            variant="contained"
                                          >
                                            N/A
                                          </CustomButton>
                                        )}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {claIssuedOn ? claIssuedOn : 'N/A'}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {vahIssuedOn ? vahIssuedOn : 'N/A'}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.select_project}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {inprogressDate}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.device_model}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.device_serialno}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.imei}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.engine_no}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.iccid}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.customer_name}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.customer_no}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.customer_altno}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.customer_address}
                                      </TableCell>
                                      {/* <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.vehicle_name}
                                      </TableCell> */}
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.vehicleModel}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.state_name}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.rto_no}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.dealer_name}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.dealer_contact_number}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.alt_dealername
                                          ? row.alt_dealername
                                          : "NA"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.alt_dealerno
                                          ? row.alt_dealerno
                                          : "NA"}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.payment_status
                                          ? row.payment_status
                                          : "N/A"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.simPlanType}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.msisdn1}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.msisdn2 === 'null' ? 'N/A' : row.msisdn2}
                                      </TableCell >
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.vehicle_oldnew}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.year_of_manufacture === null ? 'N/A' : row.year_of_manufacture}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.requestStatus}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.customerOtp
                                          ? row.customerOtp
                                          : "N/A"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {otpSentOn ? otpSentOn : "N/A"}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.reason ? row.reason : "N/A"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        <CustomButton
                                          // variant="contained"
                                          onClick={() => handleTelematics(row)}
                                        // disabled={!eoltTS}
                                        >
                                          Telematics Check
                                        </CustomButton>
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        <CustomButton
                                          variant="contained"
                                          onClick={() => handleAssign(row)}
                                          disabled={
                                            row.common_file || row.vahaan_file
                                          }
                                        >
                                          Assign
                                        </CustomButton>
                                      </TableCell>
                                    </TableRow >
                                  );
                                })
                            ) : (
                              <>No Records found!</>
                            )}
                          </TableBody >
                        </Table >
                      </TableContainer >
                      <TablePagination
                        rowsPerPageOptions={[20, 50, 100]}
                        component="div"
                        count={totalCount || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper >
                  ) : (
                    <NoData />
                  )}
                </Grid >
              </Grid >
            </Grid >
          </Grid >
        </Grid >
      </Grid >
    </>
  );
}

export default withSnackbar(CertificationManager);
