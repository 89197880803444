import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Box,
} from "@mui/material";

// styles
import useStyles from "./styles";
import {
  gql,
  useLazyQuery,
  useApolloClient,
  useMutation,
} from "@apollo/client";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import axios from "axios";
import moment from "moment";
// import SearchIcon from "@material-ui/icons/Search";
import { debounce } from "throttle-debounce";

// components
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import SearchIcon from "@mui/icons-material/Search";
import { client2 } from "./client";
import MaxWidthDialog from "./editImei";
import BulkUploadDialog from "./uploadDialog";
import DeviceUploadSample from "../../images/Device-Upload-Sample.xlsx";
import withSnackbar from "../../components/Snackbar/withSnackbar";
// data
import mock from "../dashboard/mock";
import TableComponent from "../dashboard/components/Table/Table";
import { debounceSearchRender } from "mui-datatables";
import { fetchSearchResultsNumber1 } from "../../utils";
import { ColorButton, ColorSampleButton } from "../../hoc/PaletteComponent";
import { CustomCircularProgress, NoData } from "../../hoc/CustomComponents";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FileSaver from "file-saver";

const getFeatureAccess = gql`
  query($featureId: Int!) {
    getFeatureAccess(featureId: $featureId) {
      id
      userId
      featureId
      createPermission
      readPermission
      updatePermission
      deletePermission
    }
  }
`;

const GET_UPLOAD_URL = gql`
  mutation($fileExtension: String!) {
    getPublicUploadURL(fileExtension: $fileExtension) {
      bucketName
      filename
      publicUploadURL
    }
  }
`;

const getTCUData = gql`
  query(
    $OEMId: Int!
    $id: Int
    $limit: Int!
    $offset: Int!
    $status: String
    $search: SearchInput
  ) {
    getTCUInventory(
      OEMId: $OEMId
      id: $id
      status: $status
      limit: $limit
      offset: $offset
      search: $search
    ) {
      totalCount
      rows {
        id
        oem {
          id
          oemName
          oemCode
          oemEmail
          phoneNumber
          logo
          __typename
        }
        tcu {
          id
          vendorId
          vendorCode
          tcuName
          ownerId
          tcu_code
          __typename
        }
        imei
        serialNumber
        deviceMake
        owner
        createdBy
        createdOn
        __typename
      }
      __typename
    }
  }
`;

const excelFileUpload = gql`
  mutation tcuExcelFileUpload(
    $fileInfo: FileUploadInput!
    $commonInput: CommonInput!
  ) {
    tcuExcelFileUpload(fileInfo: $fileInfo, commonInput: $commonInput) {
      failedToUpload
      totalExcelDataRecords
      totalDuplicateRecords
      successfullyUploaded
      failedUploadList
    }
  }
`;

const EXCEL_DOWNLOAD = gql`
  mutation($oemId: Int!, $downloadFor: String!) {
    excelFileDownload(oemId: $oemId, downloadFor: $downloadFor) {
      downloadUrl
    }
  }
`;

const DeviceUploadcolumns = [
  {
    name: "imei",
    label: "IMEI",
  },
  {
    name: "serialNumber",
    label: "Serial_No",
  },
  { name: "Device_Make", label: "DeviceMake" },
  {
    name: "error",
    label: "Error",
  },
];

const columns = [
  {
    label: "Sr.No",
    minWidth: 100,
    align: "center",
  },
  {
    id: "device_manuf_code",
    label: "Device Manuf Code",
    minWidth: 170,
    align: "center",
  },
  {
    id: "device_model_code",
    label: "Device Model Code",
    minWidth: 170,
    align: "center",
  },
  {
    id: "device_model",
    label: "Device Model",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "device_imei",
    label: "Device IMEI",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "device_serial",
    label: "Device Serial",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "device_make",
    label: "Device Make",
    minWidth: 130,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "createdBy",
    label: "Created By",
    minWidth: 170,
    align: "center",
  },
  {
    id: "createdOn",
    label: "Created On",
    minWidth: 170,
    align: "center",
  },
];

function Tables(props) {
  console.log("propsss-----",props)
  const client = useApolloClient();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [editId, setEditId] = React.useState();
  const [oemId, setOemId] = React.useState();
  const [searchInput, setSearchInput] = React.useState("");
  let [next, setNext] = useState(0);
  const [imeiSearchValue, setImeiSearchValue] = useState("");
  const [billingPlanId, setBillingPlanId] = useState("");
  const [tcuId, setTcuId] = useState("");
  const [isUpload, setisUpload] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [allData, setAlldata] = useState(null);

  const handleChangePage = (event, newPage) => {
    console.log("handleCHange");
    // setSearchInput("");
    setNext(newPage * rowsPerPage);
    setPage(newPage);
    getTCUInventory({
      variables: {
        OEMId: props.oem,
        limit: rowsPerPage,
        offset: newPage,
        status: "1",
        search: {
          imei: searchInput,
        },
      },
    });
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
  const [isDelete, setDelete] = useState(false);

  const handleOpen = (id, oemId) => {
    setDelete(false);
    setIsOpen(!isOpen);
    setEditId(id);
    setOemId(oemId);
    console.log("id---", id);
  };

  const handleDialogOpen = (id, oemId) => {
    // setDelete(false);
    // setIsOpen(false);
    setIsBulkUploadOpen(true);
    setOemId(props.oem);
  };

  const handleDelete = (id, oemId) => {
    setIsOpen(true);
    setEditId(id);
    setOemId(oemId);
    setDelete(true);
  };

  //const client = useApolloClient();

  let rows;

  const [readPermission, setReadPermission] = useState(false);
  const [createPermission, setCreatePermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [excelData, setexcelData] = useState([]);
  const [Excelerror, setExcelerror] = useState();
  const [tcuData, setTcuData] = useState(null);
  const [bulkDeviceResponse, setBulkDeviceResponse] = useState(null);

  //console.log("proppsss---", props.featureId);

  let id;

  if (typeof props.featureId != "undefined") {
    localStorage.setItem("featureId", props.featureId);
    id = props.featureId;
  } else if (parseInt(localStorage.getItem("featureId"))) {
    id = parseInt(localStorage.getItem("featureId"));
  } else {
    id = 2;
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    // setSearchInput("");
    setPage(0);
    setNext(0);
    getTCUInventory({
      variables: {
        OEMId: props.oem,
        limit: event.target.value,
        offset: 0,
        status: "1",
        search: {
          imei: searchInput,
        },
      },
    });
  };

  // useEffect(() => {
  //   if (props.oem) {
  //     getTCUInventory({
  //       variables: {
  //         OEMId: props.oem,
  //         limit: rowsPerPage,
  //         offset: page,
  //         status: "1",
  //       },
  //     });
  //   }
  // }, [props.oem]);

  useEffect(() => {
    getAccess({
      variables: {
        featureId: id,
      },
    });

    getTCUInventory({
      variables: {
        OEMId: props.oem,
        limit: rowsPerPage,
        offset: page,
        status: "1",
      },
    });

    //removed previous snackbar state
    setSearchInput("");
    props.setSnackbar(false);
  }, [props.oem]);

  const [getAccess, { loading, error, data }] = useLazyQuery(getFeatureAccess, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      console.log("resss----", result);
      if (result && result.getFeatureAccess) {
        result.getFeatureAccess.forEach((element) => {
          if (element.readPermission === 1) {
            setReadPermission(true);
          } else {
            setReadPermission(false);
          }
          if (element.createPermission === 1) {
            setCreatePermission(true);
          } else {
            setCreatePermission(false);
          }
          if (element.updatePermission === 1) {
            setUpdatePermission(true);
          } else {
            setUpdatePermission(false);
          }
          if (element.deletePermission === 1) {
            setDeletePermission(true);
          } else {
            setDeletePermission(false);
          }
        });
      }
    },
  });

  const [
    excelUpload,
    { loading: excelLoading, error: excelError, data: exceldata },
  ] = useMutation(excelFileUpload, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        props.setExcelError(`Message: ${networkError}`);
        props.closeDialog(false);
        props.setExcelData(null);
        props.setUpload(false);
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setExcelError(`Message: ${message}`);
          props.closeDialog(false);
          props.setExcelData(null);
          props.setUpload(false);
          props.setMessage("File headers are not Same as a given template!");
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
    },
    onCompleted: (ressss) => {
      console.log(ressss.tcuExcelFileUpload, "ressss.tcuExcelFileUpload");
      if (ressss?.tcuExcelFileUpload?.totalExcelDataRecords == 0) {
        props.setNetworkError(true);
        props.setUpload(false);
        props.setMessage("Empty file is not allowed");
        props.setSnackbar(true);
      } else {
        props.setColumns(DeviceUploadcolumns);
        props.setTitle("Device Uploads");
        props.setMessage(
          "The file data has been uploaded. Click on 'View' to view the result."
        );
        props.setUpload(true);

        //props.closeDialog(true);

        props.setExcelData(ressss.tcuExcelFileUpload);
        props.setNetworkError(false);
        props.setUpload(true);
        props.setSnackbar(true);
        getTCUInventory({
          variables: {
            OEMId: props.oem,
            limit: rowsPerPage,
            offset: page,
            status: "1",
          },
        });
      }
    },
  });

  console.log("Data-----", excelLoading);

  /**
   * @summary handles Search value
   */
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  }
  
  /**
   * @summary returns the filtered array for search
   */
  const debounceSearchRender = useCallback(debounce(async (input) => {
    console.log(props)
    if (input) 
    {
      await getTCUInventoryForSearch({
        variables: {
          OEMId: props.oem,
          limit: rowsPerPage,
          offset: 0,
          status: "1",
          search: {
            imei: input.trim(),
          },
        },
      });
    } else {
      setPage(0);
      setNext(0);
      await getTCUInventory({
        variables: {
          OEMId: props.oem,
          limit: rowsPerPage,
          // offset: page,
          offset: 0,
          status: "1",
        },
      });
    }
  }, 1000), [props.oem,totalCount, rowsPerPage, page]);
 
  const handleSearchChange = (event) => {
    const input = event.target.value;
    setSearchInput(input);
    debounceSearchRender(input);
  };

  // /**
  //  * @summary handles Search value
  //  */
  // const handleSearchChange = (event) => {
  //   // setFilterText(event.target.value);
  //   setSearchInput(event.target.value);
  //   setTimeout(debounceSearchRender(event.target.value), 2000);
  // };

  // /**
  //  * @summary returns the filtered array for search
  //  */
  // const debounceSearchRender = debounce(1000, async (event) => {
  //   if (event) {
  //     await getTCUInventoryForSearch({
  //       variables: {
  //         OEMId: props.oem,
  //         limit: totalCount,
  //         offset: 0,
  //         status: "1",
  //         search: {
  //           imei: event.trim(),
  //         },
  //       },
  //     });
  //   } else {
  //     await getTCUInventory({
  //       variables: {
  //         OEMId: props.oem,
  //         limit: rowsPerPage,
  //         offset: page,
  //         status: "1",
  //       },
  //     });
  //   }
  // });
  const [getTCUDetails, { data: tcu }] = useLazyQuery(getTCUData, {
    fetchPolicy: "network-only",
    onCompleted: async (result) => {
      console.log("result", result.getTCUInventory.rows);
      setTcuData(result.getTCUInventory.rows);
      setTotalCount(result.getTCUInventory.totalCount);
    },
  });





  const [
    getTCUInventory,
    { loading: tcuLoading, error: tcuError, data: tcuResponse },
  ] = useLazyQuery(getTCUData, {
    fetchPolicy: "network-only",
    onCompleted: async (result) => {
      console.log("result", result.getTCUInventory.rows);
      setTcuData(result.getTCUInventory.rows);
      setTotalCount(result.getTCUInventory.totalCount);
      // setRowsPerPage(20);

    },
  });
  const [
    getTCUInventoryForSearch,
    { loading: tculoading, },
  ] = useLazyQuery(getTCUData, {
    fetchPolicy: "network-only",
    onCompleted: async (result) => {
      console.log("result", result.getTCUInventory.rows);
      setTcuData(result.getTCUInventory.rows);
      setTotalCount(result.getTCUInventory.totalCount);


    },
  });

  /**
   * @summary Fetches a url for uploading a file and uploads the file
   */
  const onUpload = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    // setIsBulkUploadOpen(false);
    console.log("file----", file);
    console.log("validdiiitt---", validity);
    if (file) {
      //setUpload(true);
      //setProgressDialog(true);

      props.setMessage("");

      props.setExcelError("");
      props.closeDialog(false);
      props.setExcelData(null);
      props.setUpload(false);
      props.setNetworkError(false);
      if (validity.valid) {
        const fileExtension = file.name.substring(
          file.name.lastIndexOf(".") + 1
        );
        if (fileExtension !== "xlsx") {
          props.setMessage("Only xlsx files are allowed");
          props.setNetworkError(true);
          props.setSnackbar(true);
          handleClose();
        } else {
          const response = await client2.mutate({
            mutation: GET_UPLOAD_URL,
            variables: {
              fileExtension,
            },
            errorPolicy: "all",
          });
          console.log("response----", response.data);
          if (response.data && response.data.getPublicUploadURL) {
            console.log("response----", response.data);
            const url = response.data.getPublicUploadURL.publicUploadURL;
            await axios.put(url, file, {
              headers: {
                'x-ms-blob-type': 'BlockBlob',
              },
              onUploadProgress: (data) => {
                //Set the progress value to show the progress bar
                let percentage = Math.round((100 * data.loaded) / data.total);
                props.setMessage(`Uploading....`);
                props.setSnackbar(true);
                handleClose();
              },
            });
            setBulkDeviceResponse(response);
          } else {
            props.setMessage("Something went wrong,Please Try again!");
            props.setSnackbar(true);
            handleClose();
          }
        }
      }
    } else {
      console.log("file----", file);
      alert("No file selected");
    }
  };

  const handleBulkUpload = async () => {
    const { bucketName, filename } = bulkDeviceResponse.data.getPublicUploadURL;
    props.setUpload(true);
    const response = await excelUpload({
      variables: {
        fileInfo: {
          uploadFor: "DeviceDetailsUpload",
          bucketName,
          fileName: filename,
        },
        commonInput: {
          oemId: props.oem,
          billingPlanId,
          tcuId,
          createdBy: props.loginUser,
        },
      },
    });
    handleCloseBulk();
    setBulkDeviceResponse(null);
  };

  const handleCloseBulk = () => {
    setIsBulkUploadOpen(false);
  };

  /**
   * @summary closes the snackbar
   */
  const handleClose = () => {
    setTimeout(() => {
      props.setSnackbar(false);
    }, 7000);
  };

  const [getDownloadUrl, { data: url, loading: downloading }] = useMutation(
    EXCEL_DOWNLOAD,
    {
      fetchPolicy: "network-only",
      onError: ({ graphQLErrors, networkError }) => {
        if (networkError) {
          props.setMessage(`Message: ${networkError}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        }
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            props.setMessage(`Message: ${message}`);
            props.setNetworkError(true);
            props.setSnackbar(true);
          });
        }
      },
      onCompleted: async (result) => {
        console.log(result, "result");
        if (result.excelFileDownload.downloadUrl) {
          const res = await axios({
            url: result.excelFileDownload.downloadUrl,
            method: "GET",
            headers: { Accept: "application/vnd.ms-excel" },
            responseType: "blob", // important
          });

          FileSaver.saveAs(new Blob([res.data]), "DeviceManager.xlsx");
        } else {
          props.setMessage("Something went wrong,Please Try again!");
          props.setSnackbar(true);
          handleClose();
        }
      },
    }
  );

  const DownloadExcel = async () => {
    getDownloadUrl({
      variables: {
        oemId: props.oem,
        downloadFor: "DEVICE_MANAGER",
      },
    });
  };

  return (
    <>
      <BulkUploadDialog
        isDialogOpened={isBulkUploadOpen}
        handleCloseDialog={() => handleCloseBulk()}
        oemId={props.oem}
        upload={onUpload}
        bulkUploadDevice={handleBulkUpload}
        setBillingPlanId={setBillingPlanId}
        setTcuId={setTcuId}
        tcuId={tcuId}
        billingPlanId={billingPlanId}
        bulkDeviceResponse={bulkDeviceResponse}
        snackbarOpen={props.open}
        excelLoading={excelLoading}
      />
      <MaxWidthDialog
        isDialogOpened={isOpen}
        handleCloseDialog={() => {
          setIsOpen(false);
          getTCUInventory({
            variables: {
              OEMId: props.oem,
              limit: rowsPerPage,
              offset: page,
              status: "1",
            },
          });
        }}
        tcuInventoryId={editId}
        oemTcuInventoryId={oemId}
        isDelete={isDelete}
        updatePermission={updatePermission}
      />
      <Grid container spacing={1} style={{ backgroundColor: "#F3F3F3", maxHeight: '80vh' }}>
        <Grid item xs={12}>
          <Typography style={{}} variant="h4">
            Device Manager
          </Typography>
        </Grid>
        {loading ? (
          <CircularProgress style={{ color: "#57B793" }} />
        ) : (
          <>
            {readPermission ? (
              <>
                <Grid
                  container
                  style={{ backgroundColor: "#FFFFFF", margin: "5px" }}
                >
                  <Grid container style={{ padding: "8px" }}>
                    <Grid item xs={6}>
                      <TextField
                        id="search_imei"
                        placeholder="Search Number"
                        variant="outlined"
                        style={{
                          width: "80%",
                          backgroundColor: "#F3F3F3 ",
                        }}
                        size="small"
                        value={searchInput}
                        onChange={(e) => handleSearchChange(e)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon style={{ paddingLeft: "3px" }} />
                            </InputAdornment>
                          ),
                        }}
                        className={classes.searchTextfield}
                      />
                      {downloading ? (
                        <CircularProgress style={{ color: "#57B793" }} />
                      ) : (
                        <IconButton
                          title="Download Excel"
                          aria-label="Download Excel"
                          onClick={DownloadExcel}
                          disabled={totalCount == 0}
                        >
                          <CloudDownloadIcon
                            style={{ fill: "#57B793", fontSize: 30 }}
                          />
                        </IconButton>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container justifyContent="flex-end" spacing={2}>
                        {createPermission ? (
                          <>
                            <Grid item xs={4}>
                              <ColorSampleButton
                                href={DeviceUploadSample}
                                className={classes.sampleButton}
                                download="Device-Upload-Sample"
                              >
                                <Typography style={{ color: "white" }}>
                                  {" "}
                                  Sample File
                                </Typography>
                              </ColorSampleButton>
                            </Grid>
                            <Grid item xs={4}>
                              <ColorButton
                                onClick={() => handleDialogOpen()}
                              // style={{
                              //   paddingLeft: "25px",
                              //   paddingRight: "25px",
                              // }}
                              >
                                <Typography> Add New Device</Typography>
                              </ColorButton>
                            </Grid>
                          </>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className={classes.bod} item xs={12} style={{ maxHeight: '90vh' }}>
                    {tcuLoading || tculoading ? (
                      <CustomCircularProgress />
                    ) : (
                      <>
                        {tcuData && tcuData.length > 0 ? (
                          <>
                            <Paper>
                              <TableContainer
                                className={classes.container}
                                key={tcuData}
                                // style={{ maxHeight: '570px' }}
                                style={{ maxHeight: "calc(100vh - 260px)" }}
                              >
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      {columns.map((column) => (
                                        <>
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                              minWidth: column.minWidth,
                                              font:
                                                "normal normal 600 15px/20px Nunito Sans",
                                              letterSpacing: "0px",
                                              color: "#212121",
                                              borderRightWidth: 1,
                                              borderColor: "#E8E8E8",
                                              borderTop: "1px solid #E8E8E8",
                                              backgroundColor: "#faf8f7",
                                              padding: '8px'
                                            }}
                                          >
                                            {column.label}
                                          </TableCell>
                                        </>
                                      ))}
                                      {updatePermission || deletePermission ? (
                                        <TableCell
                                          align="center"
                                          style={{
                                            font:
                                              "normal normal 600 15px/20px Nunito Sans",
                                            letterSpacing: "0px",
                                            color: "#212121",
                                            borderRightWidth: 1,
                                            borderColor: "#E8E8E8",
                                            borderTop: "1px solid #E8E8E8",
                                            backgroundColor: "#faf8f7",
                                            minWidth: 170,
                                            padding: '8px'
                                          }}
                                        >
                                          Action
                                        </TableCell>
                                      ) : null}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {tcuData ? (
                                      tcuData.map((row, index) => {
                                        return (
                                          <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                          >
                                            <TableCell
                                              align="center"
                                              className={classes.tableContent}
                                            >
                                              {next + (index + 1)}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              className={classes.tableContent}
                                            >
                                              {row.tcu.vendorCode}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              className={classes.tableContent}
                                            >
                                              {row.tcu.tcu_code}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              className={classes.tableContent}
                                            >
                                              {row.tcu.tcuName}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              className={classes.tableContent}
                                            >
                                              {row.imei}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              className={classes.tableContent}
                                            >
                                              {row.serialNumber}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              className={classes.tableContent}
                                            >
                                              {row.deviceMake}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              className={classes.tableContent}
                                            >
                                              {row?.createdBy
                                                ? row.createdBy
                                                : "NA"}
                                            </TableCell>

                                            <TableCell
                                              align="center"
                                              className={classes.tableContent}
                                            >
                                              {row.createdOn ? (moment.unix(row.createdOn).format("YYYY-MM-DD HH:mm:ss")) : "NA"}
                                            
                                            </TableCell>
                                            {updatePermission ||
                                              deletePermission ? (
                                              <TableCell
                                                className={classes.tableContent}
                                              >
                                                <Grid
                                                  container
                                                  style={{
                                                    height: "2px",
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                  }}
                                                  alignContent="center"
                                                >
                                                  {updatePermission ? (
                                                    <Grid item>
                                                      <Button
                                                        onClick={() =>
                                                          handleOpen(
                                                            row.id,
                                                            row.oem.id
                                                          )
                                                        }
                                                      >
                                                        <CreateIcon
                                                          style={{
                                                            fill: "#2b2924",
                                                          }}
                                                        />
                                                      </Button>
                                                    </Grid>
                                                  ) : null}
                                                  {deletePermission ? (
                                                    <>
                                                      <Grid item>
                                                        <Button
                                                          onClick={() =>
                                                            handleDelete(
                                                              row.id,
                                                              row.oem.id
                                                            )
                                                          }
                                                        >
                                                          <DeleteIcon
                                                            style={{
                                                              fill: "#2b2924",
                                                            }}
                                                          />
                                                        </Button>
                                                      </Grid>
                                                    </>
                                                  ) : null}
                                                </Grid>
                                              </TableCell>
                                            ) : null}
                                          </TableRow>
                                        );
                                      })
                                    ) : (
                                      <NoData />
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <TablePagination
                                rowsPerPageOptions={[20, 50, 100]}
                                component="div"
                                count={totalCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                            </Paper>
                          </>
                        ) : (
                          <NoData />
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <Alert className={classes.pageTitle} severity="error">
                    You dont have access to this feature- Contact your Admin!
                  </Alert>
                </Grid>
              </>
            )}
          </>
        )}
        {/* {props.isDialogVisible ? (
          <AlertDialog
            isDialogOpened={props.isDialogVisible}
            handleCloseDialog={() => props.closeDialog(false)}
            value={Excelerror}
            excelData={excelData}
          />
        ) : null} */}
      </Grid>
    </>
  );
}

export default withSnackbar(Tables);
