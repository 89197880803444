import {
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TextField,
  Typography,
  TableHead,
  TablePagination,
  Button,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { CustomCircularProgress, NoData } from "../../hoc/CustomComponents";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import useStyles from "./styles";
import { TableRow } from "@material-ui/core";
import { ColorButton } from "../../hoc/PaletteComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import AddEditDealer from "./AddEditDealer";
import withSnackbar from "../../components/Snackbar/withSnackbar";
import { debounce } from "throttle-debounce";
import { check } from "prettier";
// import { Button } from "@mui/base";
const getFeatureAccess = gql`
  query($featureId: Int!) {
    getFeatureAccess(featureId: $featureId) {
      id
      userId
      featureId
      createPermission
      readPermission
      updatePermission
      deletePermission
    }
  }
`;

const GET_ALL_DEALER = gql`
  query($oemId: Int!) {
    getDealerDetails(oemId: $oemId) {
      code
      message
      rows {
        oemId
        amOemId
        login_id
        username
        login_type
        phoneNo
        dealerCode
        dealerEmail
        addressLine1
        addressLine2
        bankName
        accHoldingPerson
        accNumber
        ifsc
        gst
        pan
        countryName
        stateName
        cityName
        pincode
        status
        loginId
        amOemname
        am_oem_id
      }
    }
  }
`;

const CREATE_DEALER = gql`
  mutation(
    $oemId: Int!
    $username: String!
    $password: String!
    $loginType: String!
    $phoneNo: String!
    $dealerCode: String!
    $dealerEmail: String!
    $addressLine1: String!
    $addressLine2: String!
    $bankName: String
    $accHoldingPerson: String
    $accNumber: String
    $ifsc: String
    $gst: String
    $pan: String
    $countryName: String
    $stateName: String
    $cityName: String
    $pincode: String
    $am_oem_id: Int!
  ) {
    addDealerDetails(
      oemId: $oemId
      username: $username
      password: $password
      loginType: $loginType
      phoneNo: $phoneNo
      dealerCode: $dealerCode
      dealerEmail: $dealerEmail
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      bankName: $bankName
      accHoldingPerson: $accHoldingPerson
      accNumber: $accNumber
      ifsc: $ifsc
      gst: $gst
      pan: $pan
      countryName: $countryName
      stateName: $stateName
      cityName: $cityName
      pincode: $pincode
      am_oem_id: $am_oem_id
    ) {
      code
      message
    }
  }
`;

const GET_ALL_COUNTRIES = gql`
  query {
    getAllCountries {
      id
      name
      iso3
      numeric_code
      iso2
      phoneCode
      capital
      currency
      currency_name
      region
      subregion
      timezones
      latitude
      longitude
      emoji
    }
  }
`;

const GET_STATES = gql`
  query getStateByCountryId($countryId: Int!) {
    getStateByCountryId(countryId: $countryId) {
      id
      name
      country_id
      country_code
      fips_code
      iso2
      latitude
      longitude
    }
  }
`;

const GET_CITIES = gql`
  query getCityByStateId($stateId: Int!) {
    getCityByStateId(stateId: $stateId) {
      id
      name
      state_id
      state_code
      country_id
      country_code
      latitude
      longitude
    }
  }
`;

const getOemQuery = gql`
  query {
    getOem {
      id
      oemName
      oemCode
      oemEmail
      phoneNumber
      logo
    }
  }
`;

const UPDATE_DEALER = gql`
  mutation(
    $dealerId: Int!
    $phoneNo: String
    $dealerCode: String
    $dealerEmail: String!
    $addressLine1: String!
    $addressLine2: String!
    $bankName: String
    $accHoldingPerson: String
    $accNumber: String
    $ifsc: String
    $gst: String
    $pan: String
    $countryName: String
    $stateName: String
    $cityName: String
    $pincode: String
  ) {
    updateDealerDetails(
      dealerId: $dealerId
      phoneNo: $phoneNo
      dealerCode: $dealerCode
      dealerEmail: $dealerEmail
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      bankName: $bankName
      accHoldingPerson: $accHoldingPerson
      accNumber: $accNumber
      ifsc: $ifsc
      gst: $gst
      pan: $pan
      countryName: $countryName
      stateName: $stateName
      cityName: $cityName
      pincode: $pincode
    ) {
      code
      message
    }
  }
`;

const columns = [
  {
    name: "Sr no",
    minWidth: 100,
  },
  {
    name: "Username",
    minWidth: 200,
  },
  {
    name: "Phone No",
    minWidth: 200,
  },
  {
    name: "Email",
    minWidth: 200,
  },
  {
    name: "Address Line 1",
    minWidth: 800,
  },
  {
    name: "Address Line 2",
    minWidth: 800,
  },
  {
    name: "Account Holder Name",
    minWidth: 200,
  },
  {
    name: "City",
    minWidth: 200,
  },
  {
    name: "State",
    minWidth: 200,
  },
];

const Dealer = (props) => {
  const classes = useStyles();
  const [readPermission, setReadPermission] = useState(false);
  const [createPermission, setCreatePermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [details, setDetails] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  let [next, setNext] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [modal, setModal] = useState(false);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [phoneNo, setPhoneNo] = useState();
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  const [dealerCode, setDealerCode] = useState();
  const [addressL1, setAddL1] = useState("");
  const [addressL2, setAddL2] = useState("");
  const [bank, setBank] = useState("");
  const [accPerson, setAccperson] = useState("");
  const [accNo, setAccno] = useState("");
  const [ifsc, setIFSC] = useState("");
  const [gst, setGST] = useState("");
  const [pan, setPan] = useState("");
  const [country, setCountry] = useState({ label: "" });
  const [state, setState] = useState({ label: "" });
  const [city, setCity] = useState({ label: "" });
  const [pinCode, setPinCode] = useState("");
  const [amOemId, setAmOemId] = useState({
    label: "",
    value: "",
  });
  const [allAmOems, setAllAmOems] = useState([]);
  const [dealerId, setDealerId] = useState("");
  const [allCountry, setAllCountry] = useState([]);
  const [allState, setAllState] = useState([]);
  const [allCity, setAllCity] = useState([]);
  const [edit, setEdit] = useState(false);
  const [allData, setAlldata] = useState([]);
  const [search, setSearch] = useState("");
  const [bankValidity, setBankValidity] = useState(true);
  const [isValidEmail, setEmailValid] = useState(true);
  const [isAddL1Valid, setAddL1Valid] = useState(true);
  const [isAddL2Valid, setAddL2Valid] = useState(true);
  const [isPersonValid, setPersonValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isIFSCValid, setIsIFSCValid] = useState(true);
  const [isGstValid, setIsGstValid] = useState(true);
  const [isPanValid, setIsPanValid] = useState(true);
  const [isAccNoValid, setIsAccNoValid] = useState(true);
  const [pinCodeValid, setPinValid] = useState(true);
  const [isDealerCodeValid, setDealerCodeValid] = useState(true)
  const handleChangePage = (event, newPage) => {
    // setSearchInput("");
    // setCertificateData(allData);
    setNext(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // setSearchInput("");
    // setCertificateData(allData);
    setRowsPerPage(+event.target.value);
    setPage(0);
    setNext(0);
  };
  console.log("here");
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setTimeout(debounceSearchRender(e.target.value), 2000);
  };
  const debounceSearchRender = debounce(1000, async (event) => {
    if (event) {
      const filteredRows = allData.filter((row) => {
        const searchText = event.toLowerCase().trim();

        return (
          (row.username && row.username.toLowerCase().includes(searchText)) ||
          (row.dealerEmail &&
            row.dealerEmail.toLowerCase().includes(searchText))
        );
      });
      console.log(filteredRows, "fil");
      setDetails(filteredRows);
      setTotalCount(filteredRows.length);
      //to manage the pagination on search ,setting pagination to intial values
      // setRowsPerPage(20);
      setPage(0);
      setNext(0);
    } else {
      setPage(0);
      setNext(0);
      getDealerDetails({
        variables: {
          oemId: props.oem,
        },
      });
    }
  });

  const handleOpenModel = (row) => {
    setModal(true);
  };
  const handleUsername = (e) => {
    setUsername(e);
  };

  const handlePassword = (e) => {
    setPassword(e);
  };

  const handlePhoneNo = (e) => {
    setPhoneNo(e);
  };
  const handleDealerCode = (e) => {
    setDealerCode(e);
  };
  const handleAddressL1 = (e) => {
    setAddL1(e);
  };
  const handleAddressL2 = (e) => {
    setAddL2(e);
  };
  const handleBank = (e) => {
    setBank(e);
  };
  const handleAccPerson = (e) => {
    setAccperson(e);
  };
  const handleIfsc = (e) => {
    setIFSC(e);
  };
  const handleGst = (e) => {
    setGST(e);
  };
  const handlePan = (e) => {
    setPan(e);
  };

  const handleEmail = (e) => {
    setEmail(e);
  };

  const handleAmOemId = (e, select) => {
    if (select) {
      setAmOemId({
        label: select.label,
        value: select.value,
      });
    } else {
      setAmOemId({
        label: "",
        value: "",
      });
    }
  };

  const hanldeCountry = (e, select) => {
    console.log(select, "select");
    if (select) {
      getAllStates({
        variables: {
          countryId: select.value,
        },
      });
      setCountry({ label: select.label });
    } else {
      setCountry({ label: "" });
    }
    setState({ label: "" });
    setCity({ label: "" });
  };

  const handleState = async (e, select) => {
    if (select) {
      await getCities({
        variables: {
          stateId: select.value,
        },
      });
      setState({ label: select.label });
    } else {
      setState({ label: "" });
    }

    setCity({ label: "" });
  };

  const handleCity = async (e, select) => {
    if (select) {
      setCity({ label: select.label });
    } else {
      setCity({ label: "" });
    }
  };
  const handleAccNo = (e) => {
    setAccno(e);
  };

  const handlePinCode = (e) => {
    setPinCode(e);
  };

  const checkPhoneValidity = (e) => {
    if (phoneNo) {
      const regex = new RegExp(/^[0-9]{6,16}$/);
      setIsPhoneValid(regex.test(phoneNo));
    } else {
      setIsPhoneValid(true);
    }
  };
  const checkAccountNumberValidity = () => {
    if (accNo) {
      const regex = new RegExp(
        // eslint-disable-next-line
        /^\d{9,18}$/
      );
      setIsAccNoValid(regex.test(accNo));
    } else {
      setIsAccNoValid(true);
    }
  };

  const checkIfscValidity = () => {
    if (ifsc) {
      const regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
      setIsIFSCValid(regex.test(ifsc));
    } else {
      setIsIFSCValid(true);
    }
  };

  const checkGstValidity = () => {
    if (gst) {
      const regex = new RegExp(
        // eslint-disable-next-line
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
      );
      setIsGstValid(regex.test(gst));
    } else {
      setIsGstValid(true);
    }
  };

  const checkPanNumberValidity = () => {
    if (pan) {
      const regex = new RegExp(/[A-Z]{5}[0-9]{4}[A-Z]{1}/);
      setIsPanValid(regex.test(pan));
    } else {
      setIsPanValid(true);
    }
  };

  const checkEmailValidity = () => {
    if (email) {
      const regex = new RegExp(
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      setEmailValid(regex.test(email));
    } else {
      setEmailValid(true);
    }
  };

  const CheckBankValidity = () => {
    if (bank) {
      const regex = new RegExp(/^[a-zA-Z\s]+$/);
      setBankValidity(regex.test(bank));
    } else {
      setBankValidity(true);
    }
  };

  const checkAddL1Valid = () => {
    if (addressL1) {
      // const regex = new RegExp(/^(?=.*[a-zA-Z])[a-zA-Z0-9\s]+$/);
      const regex = new RegExp(/^[a-zA-Z0-9,\s!@#$%^&*()-_=+{}\[\]:;"'<>,.?\/\\|]+$/);
      setAddL1Valid(regex.test(addressL1));
    } else {
      setAddL1Valid(true);
    }
  };

  const checkAddL2Valid = () => {
    if (addressL2) {
      // const regex = new RegExp(/^(?=.*[a-zA-Z])[a-zA-Z0-9\s]+$/);
      const regex = new RegExp(/^[a-zA-Z0-9,\s!@#$%^&*()-_=+{}\[\]:;"'<>,.?\/\\|]+$/);
      setAddL2Valid(regex.test(addressL2));
    } else {
      setAddL2Valid(true);
    }
  };

  const checkPersonValid = () => {
    if (accPerson) {
      const regex = new RegExp(/^[a-zA-Z\s]+$/);
      setPersonValid(regex.test(accPerson));
    } else {
      setPersonValid(true);
    }
  };

  const checkPinCodeValid = () => {
    if (pinCode) {
      const regex = new RegExp(/^\d{6}$/);
      setPinValid(regex.test(pinCode));
    } else {
      setPinValid(true);
    }
  };

  const handleEdit = (row) => {
    setEdit(true);
    setUsername(row.username);
    // setPassword(row.password);
    setAmOemId({
      label: "",
      value: row.amOemId,
    });
    setPhoneNo(row.phoneNo);
    setEmail(row.dealerEmail);
    setDealerCode(row.dealerCode);
    setAddL1(row.addressLine1);
    setAddL2(row.addressLine2);
    setBank(row.bankName);
    setAccperson(row.accHoldingPerson);
    setAccno(row.accNumber);
    setIFSC(row.ifsc);
    setGST(row.gst);
    setPan(row.pan);
    setCountry({
      label: row.countryName,
    });
    setState({
      label: row.stateName,
    });
    setCity({
      label: row.cityName,
    });
    setPinCode(row.pincode);
    setDealerId(row.loginId);
    setAmOemId({
      label: row.amOemname,
      value: row.am_oem_id,
    });
    setModal(true);
  };

  const AddDealer = async () => {
    let variables = {
      oemId: props.oem,
      username: username,
      password: password,
      loginType: "DL",
      phoneNo: phoneNo ? phoneNo.toString() : null,
      dealerCode: dealerCode || "NA",
      dealerEmail: email || "NA",
      addressLine1: addressL1 || "NA",
      addressLine2: addressL2 || "NA",
      bankName: bank || "NA",
      accHoldingPerson: accPerson || "NA",
      accNumber: accNo || "NA",
      ifsc: ifsc || "NA",
      gst: gst || "NA",
      pan: pan || "NA",
      countryName: country.label || "NA",
      stateName: state.label || "NA",
      cityName: city.label || "NA",
      pincode: pinCode || null,
      am_oem_id: parseInt(amOemId.value),
    };
    console.log(variables, "variables");
    await createDealer({
      variables,
    });
  };

  const closeModal = () => {
    setModal(false);
    setUsername(null);
    setPassword(null);
    setEmail(null);
    setPhoneNo("");
    setDealerCode("");
    setAddL1("");
    setAddL2("");
    setBank("");
    setAccno("");
    setAccperson("");
    setAmOemId({ label: "", value: "" });
    setIFSC("");
    setPan("");
    setGST("");
    setCountry({ label: "" });
    setState({ label: "" });
    setCity({ label: "" });
    setPinCode("");
    setEdit(false);
    setEmailValid(true);
    setBankValidity(true);
    setAddL1Valid(true);
    setAddL2Valid(true);
    setPersonValid(true);
    setIsPhoneValid(true);
    setIsIFSCValid(true);
    setIsGstValid(true);
    setIsPanValid(true);
    setIsAccNoValid(true);
    setAllCity([]);

    setAllState([]);
  };

  const editDealerDetails = async () => {
    let variables = {
      dealerId: dealerId,
      phoneNo: phoneNo || "NA",
      dealerCode: dealerCode || "NA",
      dealerEmail: email || "NA",
      addressLine1: addressL1 || "NA",
      addressLine2: addressL2 || "NA",
      bankName: bank || "NA",
      accHoldingPerson: accPerson || "NA",
      accNumber: accNo || "NA",
      ifsc: ifsc || "NA",
      gst: gst || "NA",
      pan: pan || "NA",
      countryName: country.label || "NA",
      stateName: state.label || "NA",
      cityName: city.label || "NA",
      pincode: pinCode || null,
    };
    console.log(variables, "variables");
    await updateDealer({
      variables,
    });
  };
  const handleSnackbarClose = () => {
    setTimeout(() => {
      props.setSnackbar(false);
    }, 7000);
  };

  const [getAccess, { loading, error, data }] = useLazyQuery(getFeatureAccess, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      console.log("resss----", result);
      if (result && result.getFeatureAccess) {
        result.getFeatureAccess.forEach((element) => {
          if (element.readPermission === 1) {
            setReadPermission(true);
          } else {
            setReadPermission(false);
          }
          if (element.createPermission === 1) {
            setCreatePermission(true);
          } else {
            setCreatePermission(false);
          }
          if (element.updatePermission === 1) {
            setUpdatePermission(true);
          } else {
            setUpdatePermission(false);
          }
          if (element.deletePermission === 1) {
            setDeletePermission(true);
          } else {
            setDeletePermission(false);
          }
        });
      }
    },
  });

  const [getDealerDetails, { loading: detailsLoading }] = useLazyQuery(
    GET_ALL_DEALER,
    {
      fetchPolicy: "network-only",
      onCompleted: (res) => {
        if (res.getDealerDetails) {
          setDetails(res.getDealerDetails.rows);
          setAlldata(res.getDealerDetails.rows);
          setTotalCount(res.getDealerDetails.rows.length);
        } else {
          setDetails([]);
          setTotalCount([]);
        }
      },
    }
  );
  const [getAllCountry] = useLazyQuery(GET_ALL_COUNTRIES, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      if (res.getAllCountries) {
        let allCountry = res.getAllCountries.map((country) => ({
          value: country.id,
          label: country.name,
        }));
        setAllCountry(allCountry);
      } else {
        setAllCountry([]);
      }
    },
  });

  const [getAllStates] = useLazyQuery(GET_STATES, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      if (res.getStateByCountryId) {
        let allStates = res.getStateByCountryId.map((state) => ({
          value: state.id,
          label: state.name,
        }));
        setAllState(allStates);
      }
    },
  });

  const [getCities] = useLazyQuery(GET_CITIES, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      if (res.getCityByStateId) {
        let allCities = res.getCityByStateId.map((city) => ({
          value: city.id,
          label: city.name,
        }));
        setAllCity(allCities);
      }
    },
  });

  const [getOem] = useLazyQuery(getOemQuery, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      console.log(res);
      if (res.getOem) {
        let allAm = res.getOem.map((oem) => ({
          value: oem.id,
          label: oem.oemName,
        }));
        console.log(allAm, "allAm");
        setAllAmOems(allAm);
      } else {
        setAllAmOems([]);
      }
    },
  });

  const [createDealer] = useMutation(CREATE_DEALER, {
    errorPolicy: "all",
    onCompleted: async (res) => {
      console.log(res);
      if (res.addDealerDetails.code == 200) {
        props.setMessage("Successfully Created");
        await getDealerDetails({
          variables: {
            oemId: props.oem,
          },
        });
        closeModal();
      } else {
        props.setMessage(res.addDealerDetails.message);
      }
      props.setNetworkError(true);
      props.setSnackbar(true);
      handleSnackbarClose();
    },
  });

  const [updateDealer] = useMutation(UPDATE_DEALER, {
    errorPolicy: "all",
    onCompleted: async (res) => {
      if (res.updateDealerDetails.code == 201) {
        props.setMessage("Successfully Updated");
        closeModal();
        getDealerDetails({
          variables: {
            oemId: props.oem,
          },
        });
      } else {
        props.setMessage("Failed to update");
      }
      props.setNetworkError(true);
      props.setSnackbar(true);
      handleSnackbarClose();
    },
  });

  const checkDealerCodeValid = () => {
    if (dealerCode) {
      const regex = new RegExp(/^[0-9]+$/);
      setDealerCodeValid(regex.test(dealerCode));
    } else {
      setDealerCodeValid(true);
    }

  }

  useEffect(() => {
    getAccess({
      variables: {
        featureId: 18,
      },
    });
    getDealerDetails({
      variables: {
        oemId: props.oem,
      },
    });

    getAllCountry();
    getOem();
  }, [props.oem]);

  return (
    <Grid container>
      <AddEditDealer
        open={modal}
        username={username}
        handleUsername={handleUsername}
        password={password}
        handlePassword={handlePassword}
        phoneNo={phoneNo}
        handlePhoneNo={handlePhoneNo}
        dealerCode={dealerCode}
        handleDealerCode={handleDealerCode}
        handleAddressL1={handleAddressL1}
        addressL1={addressL1}
        addressL2={addressL2}
        handleAddressL2={handleAddressL2}
        bank={bank}
        handleBank={handleBank}
        accPerson={accPerson}
        handleAccPerson={handleAccPerson}
        accNo={accNo}
        handleAccNo={handleAccNo}
        handleIfsc={handleIfsc}
        ifsc={ifsc}
        gst={gst}
        handleGst={handleGst}
        pan={pan}
        handlePan={handlePan}
        country={country}
        allCountry={allCountry}
        hanldeCountry={hanldeCountry}
        amOemId={amOemId}
        allAmOems={allAmOems}
        handleAmOemId={handleAmOemId}
        state={state}
        allState={allState}
        handleState={handleState}
        city={city}
        allCity={allCity}
        handleCity={handleCity}
        pinCode={pinCode}
        handlePinCode={handlePinCode}
        handleEmail={handleEmail}
        email={email}
        AddDealer={AddDealer}
        closeModal={closeModal}
        edit={edit}
        editDealerDetails={editDealerDetails}
        checkEmailValidity={checkEmailValidity}
        isValidEmail={isValidEmail}
        bankValidity={bankValidity}
        CheckBankValidity={CheckBankValidity}
        checkAddL1Valid={checkAddL1Valid}
        checkAddL2Valid={checkAddL2Valid}
        checkPersonValid={checkPersonValid}
        isAddL1Valid={isAddL1Valid}
        isAddL2Valid={isAddL2Valid}
        isPersonValid={isPersonValid}
        checkPhoneValidity={checkPhoneValidity}
        isPhoneValid={isPhoneValid}
        checkIfscValidity={checkIfscValidity}
        isIFSCValid={isIFSCValid}
        checkGstValidity={checkGstValidity}
        isGstValid={isGstValid}
        checkPanNumberValidity={checkPanNumberValidity}
        isPanValid={isPanValid}
        isAccNoValid={isAccNoValid}
        checkAccountNumberValidity={checkAccountNumberValidity}
        checkPinCodeValid={checkPinCodeValid}
        pinCodeValid={pinCodeValid}
        checkDealerCodeValid={checkDealerCodeValid}
        isDealerCodeValid={isDealerCodeValid}
      />
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4">Dealers</Typography>
          </Grid>
          <Grid item xs={12} style={{paddingTop:'0px'}}>
            <Grid container style={{ backgroundColor: "#ffffff" }}>
              <Grid item xs={12}>
                <Grid container style={{ padding: "8px 8px" }}>
                  <Grid item xs={6}>
                    <TextField
                      placeholder="Search here"
                      size="small"
                      style={{ width: "80%", backgroundColor: "#F3F3F3 " }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon style={{ paddingLeft: "3px" }} />
                          </InputAdornment>
                        ),
                      }}
                      className={classes.textfield}
                      value={search}
                      onChange={(e) => handleSearchChange(e)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container style={{ flexDirection: "row-reverse" }}>
                      <Grid item>
                        <ColorButton
                          variant="outlined"
                          onClick={() => {
                            handleOpenModel();
                          }}
                        >
                          <Typography> Create Dealer</Typography>
                        </ColorButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{maxHeight:'60vh'}}>
                {detailsLoading ? (
                  <CustomCircularProgress />
                ) : details && details.length > 0 ? (
                  <Paper>
                    <TableContainer className={classes.container} style={{maxHeight: "calc(100vh - 250px)" }}>
                      <Table stickyHeader area-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell
                                align="center"
                                style={{
                                  minWidth: column.minWidth,
                                  font:
                                    "normal normal 600 14px/18px Nunito Sans",
                                  letterSpacing: "0px",
                                  backgroundColor: "#faf8f7",
                                  color: "#212121",
                                  borderRightWidth: 1,
                                  borderColor: "#E8E8E8",
                                  borderTop: "1px solid #E8E8E8",
                                  padding:'8px'
                                }}
                              >
                                {column.name}
                              </TableCell>
                            ))}
                            {updatePermission && (
                              <TableCell
                                align="center"
                                style={{
                                  font:
                                    "normal normal 600 14px/18px Nunito Sans",
                                  letterSpacing: "0px",
                                  backgroundColor: "#faf8f7",
                                  color: "#212121",
                                  borderRightWidth: 1,
                                  borderColor: "#E8E8E8",
                                  borderTop: "1px solid #E8E8E8",
                                  padding:'8px'

                                }}
                              >
                                Action
                              </TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {details
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => (
                              <TableRow>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {next + (index + 1)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.username}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.phoneNo}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.dealerEmail}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.addressLine1}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.addressLine2}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.accHoldingPerson}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.cityName}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.stateName}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  <Grid
                                    container
                                    style={{
                                      height: "2px",
                                    }}
                                    alignContent="center"
                                  >
                                    {updatePermission ? (
                                      <Grid item>
                                        <Button onClick={() => handleEdit(row)}>
                                          <CreateIcon
                                            style={{
                                              fill: "#2b2924",
                                            }}
                                          />
                                        </Button>
                                      </Grid>
                                    ) : null}
                                    {/* {deletePermission ? (
                                    <>
                                      <Grid item>
                                        <Button
                                        //   onClick={() =>
                                        //     handleDelete(row.id, row.oem.id)
                                        //   }
                                        >
                                          <DeleteIcon
                                            style={{
                                              fill: "#2b2924",
                                            }}
                                          />
                                        </Button>
                                      </Grid>
                                    </>
                                  ) : null} */}
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[20,50, 100]}
                      component="div"
                      count={totalCount || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                ) : (
                  <NoData />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(Dealer);
